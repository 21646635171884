<template>
  <v-data-table
    :headers          = "referenceTableHeaders"
    :items            = "referenceStore.references"
    class             = "elevation-3"
    mobile-breakpoint = "1000"
    no-data-text      = "Please add three references"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat rounded>
        <v-toolbar-title>
          References
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          @click = "newReferenceModal()"
          color  = "primary"
          dark
        >
          Add Reference
        </v-btn>
        <v-dialog
          v-model   = "showReferenceModal"
          max-width = "1100px"
          persistent
        >
          <v-form
            ref="referenceForm"
            lazy-validation
          >
            <v-card>
              <v-card-title class="primary py-3">
                <h2 class="my-0 ml-3">
                  {{`Reference #${referenceNumberDisplay}`}}
                </h2>
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempReference.name"
                        label   = "Reference Name"
                        :rules  = "genericFieldRule(64)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempReference.address"
                        label   = "Reference Address"
                        :rules  = "genericFieldRule(128)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model   = "tempReference.phoneNumber"
                        v-mask    = "'###-###-####'"
                        inputmode = "tel"
                        label     = "Reference Phone Number (Optional)"
                        :rules    = "[v => (
                            v == null
                            || v === ''
                            || v.length === 12
                          ) || 'Not a valid phone number'
                        ]"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempReference.jobTitle"
                        label   = "Reference Employment Position"
                        :rules  = "genericFieldRule(50)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model   = "tempReference.yearsAcquainted"
                        v-mask    = "'##'"
                        inputmode = "numeric"
                        label     = "Years Acquainted"
                        :rules    = "[
                          v => !!v || 'Field is Required'
                        ]"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="mx-sm-4">
                <v-spacer></v-spacer>
                <v-btn
                  color  = "text"
                  @click = "closeReferenceModal()"
                  text
                >
                  Cancel
                </v-btn>
                <v-btn
                  color  = "secondary"
                  @click = "saveReferenceModal()"
                  text
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-dialog
        v-model   = "showReferenceDeleteModal"
        max-width = "600px"
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Are you sure you want to delete this Reference?
            </h4>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color  = "primary"
              @click = "hideDeleteReferenceModal()"
            >
              Cancel
            </v-btn>
            <v-btn
              color  = "error"
              @click = "deleteReference()"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class  = "ml-1 mr-1"
        @click = "editReferenceModal(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        @click="deleteReferenceModal(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue   from "vue";

import { mapStores              } from 'pinia';
import { Reference              } from "@/models/ApplicationForm";
import { useReferenceStore      } from '@/stores/referenceTable';

export default Vue.extend({
  name: "ReferenceTableComponent",

  data() {
    return {
      referenceTableHeaders: [
        {
          text:     'Name',
          align:    'center',
          sortable: false,
          value:    'name'
        },
        {
          text:     'Address',
          align:    'center',
          sortable: false,
          value:    'address'
        },
        {
          text:     'Phone Number',
          align:    'center',
          sortable: false,
          value:    'phoneNumber',
          width:    '125px'
        },
        {
          text:     'Employment Position',
          align:    'center',
          sortable: false,
          value:    'jobTitle'
        },
        {
          text:     'Years Acquainted',
          align:    'center',
          sortable: false,
          value:    'yearsAcquainted'
        },
        {
          text:     'Actions',
          align:    'center',
          sortable: false,
          value:    'actions',
          width:    '90px'
        }
      ],
      tempReference: {
        name:            "",
        address:         "",
        phoneNumber:     null,
        jobTitle:        "",
        yearsAcquainted: ""
      } as Reference,
      showReferenceModal:       false,
      showReferenceDeleteModal: false,
      currentlyEditing:         false,
      referenceIndex:           -1,
      referenceNumberDisplay:   1
    }
  },

  computed: {
    ...mapStores(useReferenceStore),
  },

  methods: {
    newReferenceModal() {
      this.tempReference = {
        name:            "",
        address:         "",
        phoneNumber:     null,
        jobTitle:        "",
        yearsAcquainted: ""
      } as Reference;
      this.referenceIndex = -1;

      this.resetValidation();

      this.currentlyEditing = false;
      this.showReferenceModal = true;
      this.referenceNumberDisplay = this.referenceStore.references.length + 1;
    },

    closeReferenceModal() {
      this.tempReference = {
        name:            "",
        address:         "",
        phoneNumber:     null,
        jobTitle:        "",
        yearsAcquainted: ""
      } as Reference;
      this.referenceIndex     = -1;
      this.showReferenceModal = false;
      this.currentlyEditing   = false;
    },

    validateReference (): boolean {
      let vForm = this.$refs.referenceForm as Vue & {validate: () => boolean};

      if (vForm == undefined) {
        return false;
      }

      return vForm.validate();
    },

    resetValidation (): void {
      let vForm = this.$refs.referenceForm as Vue & {resetValidation: () => void};

      if (vForm != undefined) {
        vForm.resetValidation();
      }
    },

    saveReferenceModal () {
      // Verifies the form is correct before saving or updating
      if (this.validateReference()) {
        // Adds a new reference if the index is less than 0 or greater than the array
        if (this.referenceIndex < 0 || this.referenceIndex > this.referenceStore.references.length) {
          // Adds a new reference
          this.referenceStore.addReference(this.tempReference);
        } else {
          // Updates an existing reference
          this.referenceStore.updateReference(this.referenceIndex, this.tempReference);
        }

        this.tempReference = {
          name:            "",
          address:         "",
          phoneNumber:     null,
          jobTitle:        "",
          yearsAcquainted: ""
        } as Reference;
        this.referenceIndex     = -1;
        this.showReferenceModal = false;

        if (
          !this.currentlyEditing
          && this.referenceStore.references.length < 3
        ) {
          this.newReferenceModal();
        }
      }
    },

    editReferenceModal (reference: Reference) {
      this.referenceIndex = this.referenceStore.references.indexOf(reference);
      this.referenceNumberDisplay = this.referenceIndex + 1;
      this.tempReference  = Object.assign(
        {}, this.referenceStore.references[this.referenceIndex]
      ) as Reference;

      this.resetValidation();

      this.currentlyEditing = true;
      this.showReferenceModal = true;
    },

    deleteReferenceModal (reference: Reference) {
      this.referenceIndex           = this.referenceStore.references.indexOf(reference);
      this.showReferenceDeleteModal = true;
    },

    hideDeleteReferenceModal () {
      this.showReferenceDeleteModal = false;
      this.referenceIndex           = -1;
    },

    deleteReference () {
      if (this.referenceIndex >= 0 && this.referenceIndex < this.referenceStore.references.length) {
        this.referenceStore.removeReference(this.referenceIndex);

        this.referenceIndex           = -1;
        this.showReferenceDeleteModal = false;
      }
    },
    genericFieldRule (maxLength: number) {
      return [
        (v:any) => !!v || 'Field is Required',
        (v:any) => (v && v.length <= maxLength) || 'Field must be less than ' + (maxLength + 1) + ' characters',
      ];
    },
  },

});
</script>

<style scoped>

</style>
