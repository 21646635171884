export interface Reference {
  name:            string;
  address:         string;
  phoneNumber:     string | null;
  jobTitle:        string;
  yearsAcquainted: string;
}

export interface FormerEmployer {
  startDate:        string | null;
  endDate:          string | null;
  employerName:     string;
  employerAddress:  string;
  salary:           string;
  positionTitle:    string;
  reasonForLeaving: string;
}

export interface Education {
  schoolTypeId:     number;
  schoolName:       string;
  address:          string;
  totalYears:       number;
  diplomaOrTradeId: number;
  subjectsStudied:  string;
}

export interface ConvictionRecord {
  offenseNature:  string;
  convictionDate: string | null;
  judgement:      string;
  courtName:      string;
  courtAddress:   string;
}

// A record from the category lookup table in the DB
export interface CategoryLookup {
  catLookupId: number;
  catId:       number;
  optName:     string;
  order:       number;
}

export enum Category {
  ReferralSource = 1,
  Skillset       = 6,
  HighSchool     = 7,
  College        = 8,
  OtherSchool    = 9,
  SchoolType     = -1
}

export interface JobSkill {
  lookupId: number;
}

export interface SelectableJobSkill {
  jobSkill:    JobSkill;
  displayName: string;
  selected:    boolean;
}

export interface Address {
  street:  string;
  city:    string;
  state:   State | null;
  zipcode: string;
}

export interface Application {
  socialSecurity:                 string;
  firstName:                      string;
  middleName:                     string;
  lastName:                       string;
  address:                        Address | null;
  phoneNumber:                    string;
  emailAddress:                   string;
  referralSourceId:               number  | null;
  referralName:                   string  | null;
  over18:                         boolean | null;
  employmentPosition:             string;
  fullTime:                       boolean | null;
  startDate:                      string  | null;
  salaryDesired:                  string;
  hasWorkedHereBefore:            boolean | null;
  currentlyEmployed:              boolean | null;
  inquirePresentEmployer:         boolean | null;
  involuntarilyDischarged:        boolean | null;
  explanationForDischarge:        string  | null;
  jobCertifications:              string  | null;
  otherJobSkills:                 string  | null;
  hasFormerEmployerHistory:       boolean;
  organizationMembership:         string  | null;
  examinationAndDrugTest:         boolean | null;
  convictedOfMisdemeanorOrFelony: boolean | null;
  agreedToTerms:                  boolean;
  hasPhotoId:                     boolean | null;
  hasPhysicalSsnCard:             boolean | null;
}

export interface EntireApplication {
  application:       Application;
  convictionRecords: ConvictionRecord[];
  references:        Reference[];
  education:         Education[];
  formerEmployers:   FormerEmployer[];
  jobSkills:         JobSkill[];
}

export interface APIResponseBody {
  error:   APIError | null,
  message: string
}

export interface ApplicationResponse {
  status: number,
  body:   APIResponseBody | null,
}

export enum APIError {
  APPLICATION_TOO_RECENT           = "The application is too recent",
  INVALID_APPLICATION              = "The application is invalid",
  INVALID_CONVICTION_RECORD        = "A conviction record is invalid",
  INVALID_REFERENCE                = "A reference is invalid",
  INVALID_FORMER_EMPLOYER          = "A former employer is invalid",
  INVALID_EDUCATION                = "An education record is invalid",
  INVALID_JOB_SKILL                = "A job skill is invalid",
  INVALID_MESSAGE_BODY             = "The HTTP message body is invalid",
  INVALID_MESSAGE_HEADER           = "A HTTP message header is missing or invalid",
  INVALID_OVERRIDE_PASSWORD        = "The provided override code is invalid",
  ADD_APPLICATION_EXCEPTION        = "An error occurred while adding an application",
  GET_RECENT_APPLICATION_EXCEPTION = "An error occurred while getting an application",
  GET_CATEGORY_LOOKUP_EXCEPTION    = "An error occurred while getting the category lookup table",
  NO_APPLICATION_FOUND             = "No application was found",
  ADD_EDUCATION_EXCEPTION          = "An error occurred while adding an education record",
  ADD_FORMER_EMPLOYER_EXCEPTION    = "An error occurred while adding a former employer",
  ADD_REFERENCE_EXCEPTION          = "An error occurred while adding a reference",
  ADD_CONVICTION_RECORD_EXCEPTION  = "An error occurred while adding a conviction record",
  ADD_JOB_SKILL_EXCEPTION          = "An error occurred while adding a job skill",
  DELETE_APPLICATION_EXCEPTION     = "An error occurred while deleting an application",
  ENCRYPTION_EXCEPTION             = "An error occurred while encrypting data",
  HASHING_EXCEPTION                = "An error occurred while hashing data",
}

export enum State {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming"
}

