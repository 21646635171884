import Vue from 'vue'

import VueRouter, { RouteConfig } from 'vue-router'

import Welcome                  from '/src/views/Welcome.vue'
import ApplicationForm          from '/src/views/ApplicationForm.vue'
import Page404                  from '/src/views/Page404.vue'
import Page503                  from '/src/views/Page503.vue'
import ApplicationSubmitSuccess from '/src/views/ApplicationSubmitSuccess.vue'
import ApplicationSubmitError   from '/src/views/ApplicationSubmitError.vue'
import ApplicationSubmitRecent  from '/src/views/ApplicationSubmitRecent.vue'
import PrivacyPolicy            from '/src/views/PrivacyPolicy.vue'
import KioskMode                from '/src/views/KioskMode.vue'
import KioskModeDisable         from '/src/views/KioskModeDisable.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path:      '/welcome',
    name:      'Welcome',
    component: Welcome
  },
  {
    path:      '/',
    name:      'Application Form',
    component: ApplicationForm
  },
  {
    path:      '/kiosk',
    name:      'Kiosk Mode',
    component: KioskMode
  },
  {
    path:      '/disable-kiosk',
    name:      'Kiosk Mode Disable',
    component: KioskModeDisable
  },
  {
    path:      '/application-submit-success',
    name:      'Application Submit Success',
    component: ApplicationSubmitSuccess
  },
  {
    path:      '/application-submit-error',
    name:      'Application Submit Error',
    component: ApplicationSubmitError
  },
  {
    path:      '/application-submit-recent',
    name:      'Application Submit Recent',
    component: ApplicationSubmitRecent
  },
  {
    path:      '/privacy-policy',
    name:      'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path:      '/503',
    name:      'Page 503',
    component: Page503
  },
  {
    path:      '/*',
    name:      'Page 404',
    component: Page404
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
