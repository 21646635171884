<template>
  <v-data-table
    :headers          = "educationTableHeaders"
    :items            = getTableDisplayData()
    class             = "elevation-3"
    mobile-breakpoint = "1000"
    no-data-text      = "Please add every school attended"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat rounded>
        <v-toolbar-title>
          Education History
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click = "newEducationModal()"
          color  = "primary"
          dark
        >
          Add School
        </v-btn>
        <v-dialog
          v-model   = "showEducationModal"
          max-width = "1100px"
          persistent
        >
          <v-form
            ref="educationForm"
            lazy-validation
          >
            <v-card>
              <v-card-title class="primary py-3">
                <h2 class="my-0 ml-3">
                  School
                </h2>
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model    = "tempEducation.schoolTypeId"
                        :items     = "getCategory(Category.SchoolType)"
                        item-title = "title"
                        item-value = "value"
                        label      = "School Type"
                        :rules     = "[v => !!v || 'Field is Required']"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempEducation.schoolName"
                        label   = "School Name"
                        :rules  = "genericFieldRule(64)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempEducation.address"
                        label   = "School City and State"
                        :rules  = "genericFieldRule(128)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model    = "tempEducation.totalYears"
                        :items     = "totalYearItems"
                        label      = "Total Years at School"
                        item-text  = "text"
                        item-value = "value"
                        :rules     = "[v => !!v || 'Field is Required']"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model = "tempEducation.diplomaOrTradeId"
                        :items  = getDiplomaOrTradeCategory()
                        :label  = getDiplomaOrTradeLabel()
                        :rules  = "[
                          v => !!v                        || 'Field is Required',
                          v => validateTradeOrDiplomaId() || 'Field is Required'
                        ]"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="12">
                      <v-text-field
                        v-model = "tempEducation.subjectsStudied"
                        label   = "Subjects Studied"
                        :rules  = "genericFieldRule(128)"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="mx-md-4">
                <v-spacer></v-spacer>
                <v-btn
                  color  = "text"
                  @click = "closeEducationModal()"
                  text
                >
                  Cancel
                </v-btn>
                <v-btn
                  color  = "secondary"
                  @click = "saveEducationModal()"
                  text
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-dialog
        v-model   = "showEducationDeleteModal"
        max-width = "600px"
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Are you sure you want to delete this School?
            </h4>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color  = "primary"
              @click = "hideDeleteEducationModal()"
            >
              Cancel
            </v-btn>
            <v-btn
              color  = "error"
              @click = "deleteEducation()"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model   = "showFollowupEducationModal"
        max-width = "600px"
        persistent
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Do you have additional education history?
            </h4>
          </v-card-title>
          <v-card-actions style="justify-content: center">
            <v-btn
              @click = "showFollowupEducationModal = false"
            >
              No
            </v-btn>
            <v-btn
              color  = "primary"
              @click = "newEducationModal"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class  = "mx-1"
        @click = "editEducationModal(item.index)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        @click="deleteEducationModal(item.index)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue                        from "vue";
import { mapStores              } from 'pinia';
import { useEducationStore      } from '@/stores/educationTable';
import { useCategoryLookupStore } from '@/stores/lookupTable';
import {
  Education,
  Category,
  CategoryLookup,
} from "@/models/ApplicationForm";

interface ItemProp {
  text:  string;
  value: number;
}

export default Vue.extend({
  name: "EducationTableComponent",

  data() {
    return {
      Category,  // Added to allow access in HTML Template
      educationTableHeaders: [
        {
          text:     'School Type',
          align:    'center',
          sortable: false,
          value:    'schoolType'
        },
        {
          text:     'School Name',
          align:    'center',
          sortable: false,
          value:    'schoolName'
        },
        {
          text:     'School Address',
          align:    'center',
          sortable: false,
          value:    'address'
        },
        {
          text:     'Total Years',
          align:    'center',
          sortable: false,
          value:    'totalYears'
        },
        {
          text:     'Diploma or Trade',
          align:    'center',
          sortable: false,
          value:    'diplomaOrTrade'
        },
        {
          text:     'Subjects Studied',
          align:    'center',
          sortable: false,
          value:    'subjectsStudied'
        },
        {
          text:     'Actions',
          align:    'center',
          sortable: false,
          value:    'actions',
          width:    '90px'
        }
      ],
      tempEducation: {
        schoolTypeId:     0,
        schoolName:       "",
        address:          "",
        totalYears:       0,
        diplomaOrTradeId: 0,
        subjectsStudied:  "",
      } as Education,

      totalYearItems: [
        { text: "1",  value: 1 },
        { text: "2",  value: 2 },
        { text: "3",  value: 3 },
        { text: "4+", value: 4 },
      ] as ItemProp[],
      showEducationModal:         false,
      showFollowupEducationModal: false,
      showEducationDeleteModal:   false,
      currentlyEditing:           false,
      educationIndex:             -1
    }
  },

  computed: {
    ...mapStores(
      useEducationStore,
      useCategoryLookupStore
    ),
  },

  methods: {
    /**
     * @name getSchoolTypeCategories
     * @input schoolTypeId - A number representing the schoolType to get category options for
     * @input defaultCatID - A schoolType catId to use in the default cases
     * @input lookupFun    - The lookup function that will perform the category option lookup
     * @desc A higher order function that returns all categories of a specific school type
     * @returns An array of objects based on the provided type
     */
    getSchoolTypeCategories<Type>(
      schoolTypeId: number,
      defaultCatId: number,
      lookupFun: (cat: Category) => Type[]
    ): Type[] {
      switch (schoolTypeId) {
        case  0: return lookupFun(defaultCatId);
        case 45: return lookupFun(Category.HighSchool);
        case 46: return lookupFun(Category.College);
        case 47: return lookupFun(Category.OtherSchool);
        default:
          console.error("Default case triggered in getSchoolTypeCategories()");
          return lookupFun(defaultCatId);
      }
    },

    // Gets the info the user will see inside the diplomaOrTrade dropdown field
    getDiplomaOrTradeCategory(): ItemProp[] {
      return this.getSchoolTypeCategories<ItemProp>(
        this.tempEducation.schoolTypeId,
        0,
        this.getCategory
      );
    },

    // Gets the label for the diplomaOrTrade dropdown field
    getDiplomaOrTradeLabel(): string {
      switch (this.tempEducation.schoolTypeId) {
        case  0: return "Diploma Received";  // Case when lookup table hasn't loaded
        case 45: return "Diploma Received";  // Case when HighSchool type is selected
        case 46: return "Diploma Received";  // Case when College type is selected
        case 47: return "School Trade";      // Case when Other type is selected
        default:
          console.error("Default case triggered in getDiplomaOrTradeLabel()");
          return "Diploma Received";
      }
    },

    // Gets the array of data that will be used by the education table element
    getTableDisplayData() {
      let index = 0;

      return this.educationStore
        .getEducation
        .map( (item: Education) => {
          return {
            // Binds an index to each record in the table so the edit/delete buttons know what data
            // should be modified
            index:           index++,
            schoolType:      this.getDisplayName(item.schoolTypeId),
            schoolName:      item.schoolName,
            address:         item.address,
            totalYears:      item.totalYears,
            diplomaOrTrade:  this.getDisplayName(item.diplomaOrTradeId),
            subjectsStudied: item.subjectsStudied,
          }
        });
    },

    // Gets the string text the user should see inside the education table from
    // a given lookup id
    getDisplayName(id: number): string {
      const cat = this.categoryLookupStore.getCategoryFromId(id);

      if (cat == null)
        return "";
      else
        return cat.optName;
    },

    // Gets an array of categories to be used in the dropdown menus where
    // text is the text the user will see in the menu and value is the lookupID
    // that will be stored when a user selects an option
    getCategory(cat: Category): ItemProp[] {
      return this.categoryLookupStore
        .getSortedCategoryOptions(cat)
        .map( opt => {
          return {
            text:  opt.optName,
            value: opt.catLookupId,
          } as ItemProp
        });
    },

    // Validates the tradeOrDiplomaId dropdown field to ensure its selected option
    // matches the schoolTypeId that is selected. If the field is not validated with this
    // function, the user can select a tradeOrDiplomaId option then switch schoolTypes
    // and the old and invalid tradeOrDiplomaId would be accepted
    validateTradeOrDiplomaId(): boolean {
      const typeCategories = this.getSchoolTypeCategories<CategoryLookup>(
        this.tempEducation.schoolTypeId,
        0,
        this.categoryLookupStore.getCategoryOptions
      );

      const lookup = typeCategories.find( item =>
        item.catLookupId === this.tempEducation.diplomaOrTradeId
      );

      return lookup != undefined;
    },

    // Validates all education input fields
    validateEducation (): boolean {
      let vForm = this.$refs.educationForm as Vue & {validate: () => boolean};

      if (vForm == undefined) {
        return false;
      }

      return vForm.validate();
    },

    // Resets the validation state
    resetValidation (): void {
      let vForm = this.$refs.educationForm as Vue & {resetValidation: () => void};

      if (vForm != undefined) {
        vForm.resetValidation();
      }
    },

    // Resets the tempEducation state
    resetTempEducation() {
      this.tempEducation = {
        schoolTypeId:      0,
        schoolName:        "",
        address:           "",
        totalYears:        0,
        diplomaOrTradeId:  0,
        subjectsStudied:   "",
      } as Education;
      this.educationIndex = -1;
    },

    // Displays a new education modal
    newEducationModal() {
      this.resetTempEducation();
      this.resetValidation();
      this.showFollowupEducationModal = false;
      this.currentlyEditing = false;
      this.showEducationModal = true;
    },

    // Closes the education modal
    closeEducationModal() {
      this.resetTempEducation();
      this.showEducationModal = false;
      this.currentlyEditing = false;
    },

    // Saves the tempEducation object into the store if it is valid
    saveEducationModal () {
      // Verifies the form is correct before saving or updating
      if (this.validateEducation()) {
        // Adds a new school if the index is less than 0 or greater than the array
        if (
          this.educationIndex < 0 ||
          this.educationIndex > this.educationStore.education.length
        ) {
          // Adds a new school
          this.educationStore.addEducation(this.tempEducation);
        } else {
          // Updates an existing school
          this.educationStore.updateEducation(this.educationIndex, this.tempEducation);
        }

        this.resetTempEducation();
        this.showEducationModal = false;

        this.showFollowupEducationModal = !this.currentlyEditing;
      }
    },

    // Displays an education modal with the state from the education record to be edited
    editEducationModal (educationIndex: number) {
      this.educationIndex = educationIndex;
      this.tempEducation  = Object.assign(
        {}, this.educationStore.education[this.educationIndex]
      ) as Education;

      this.resetValidation();
      this.currentlyEditing = true;
      this.showEducationModal = true;
    },

    // Displays the delete conformation modal
    deleteEducationModal (educationIndex: number) {
      this.educationIndex           = educationIndex;
      this.showEducationDeleteModal = true;
    },

    // Closes the delete conformation modal
    hideDeleteEducationModal () {
      this.showEducationDeleteModal = false;
      this.educationIndex           = -1;
    },

    // Deletes a specific education record object from the store
    deleteEducation () {
      if (
        this.educationIndex >= 0 &&
        this.educationIndex < this.educationStore.education.length
      ) {
        this.educationStore.removeEducation(this.educationIndex);
        this.educationIndex           = -1;
        this.showEducationDeleteModal = false;
      }
    },

    genericFieldRule (maxLength: number) {
      return [
        (v:any) => !!v || 'Field is Required',
        (v:any) => (v && v.length <= maxLength) || 'Field must be less than ' + (maxLength + 1) + ' characters',
      ];
    },
  },

});
</script>

<style scoped>

</style>
