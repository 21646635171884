import {
  Application,
  Address
} from '@/models/ApplicationForm';

import { defineStore } from 'pinia';

interface IState {
  application: Application;
  isFilledOut: boolean;
}

export const useApplicationStore = defineStore('application', {

  state: (): IState => ({
    isFilledOut: false,
    application: {
      socialSecurity: '',
        firstName:    '',
        middleName:   '',
        lastName:     '',
        address: {
        street:    '',
          city:    '',
          state:   null,
          zipcode: ''
      } as Address,
        phoneNumber:              '',
        emailAddress:             '',
        referralSourceId:         null,
        referralName:             null,
        over18:                   null,
        employmentPosition:       '',
        fullTime:                 null,
        startDate:                null,
        salaryDesired:            '',
        hasWorkedHereBefore:      null,
        currentlyEmployed:        null,
        inquirePresentEmployer:   null,
        involuntarilyDischarged:  null,
        explanationForDischarge:  null,
        jobCertifications:        null,
        otherJobSkills:           '',
        hasFormerEmployerHistory: true,
        organizationMembership:   null,
        examinationAndDrugTest:   null,
        agreedToTerms:            false,
        convictedOfMisdemeanorOrFelony: null,
        hasPhotoId:               null,
        hasPhysicalSsnCard:       null
    },
  }),

  actions: {
    resetApplication(): void {
      this.isFilledOut = false;
      this.application = {
        socialSecurity: '',
          firstName:    '',
          middleName:   '',
          lastName:     '',
          address: {
          street:    '',
            city:    '',
            state:   null,
            zipcode: ''
        } as Address,
          phoneNumber:              '',
          emailAddress:             '',
          referralSourceId:         null,
          referralName:             null,
          over18:                   null,
          employmentPosition:       '',
          fullTime:                 null,
          startDate:                null,
          salaryDesired:            '',
          hasWorkedHereBefore:      null,
          currentlyEmployed:        null,
          inquirePresentEmployer:   null,
          involuntarilyDischarged:  null,
          explanationForDischarge:  null,
          jobCertifications:        null,
          otherJobSkills:           '',
          hasFormerEmployerHistory: true,
          organizationMembership:   null,
          examinationAndDrugTest:   null,
          agreedToTerms:            false,
          convictedOfMisdemeanorOrFelony: null,
          hasPhotoId:               null,
          hasPhysicalSsnCard:       null
      };
    },
  },

});
