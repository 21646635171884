import { render, staticRenderFns } from "./ReferenceTableComponent.vue?vue&type=template&id=4b33afc8&scoped=true"
import script from "./ReferenceTableComponent.vue?vue&type=script&lang=ts"
export * from "./ReferenceTableComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b33afc8",
  null
  
)

export default component.exports