<template>
  <div>
    <header-component title="503 Service Unavailable" />
    <div class="px-4 text-center" style="max-width: 1200px; margin: 40px auto 40px auto;">
      <h1 class="pt-md-5 mb-5 error--text">
        503 Service Unavailable
      </h1>
      <h2 class="mb-3">
        The job application site is temporarily unavailable right now
      </h2>
      <div v-if = "kioskMode === false">
        <h2 class="mb-5">
          Please wait a few minutes then try again
        </h2>
        <p class="text-center">
          If the site is still unavailable after a few minutes, you can contact the Human
          Resources Department by email at human.resources@wwinc.com or phone at (573)614-4765.
          Sorry for any inconvenience.
        </p>
      </div>
      <div v-else>
        <p class="text-center mt-6">
          Please return the tablet back to the desk and let them know there
          is an issue with the job application.
        </p>
      </div>
      <v-btn
        class = "mt-5"
        color  = "primary"
        @click = "reload"
      >
        Try Again
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue                        from "vue";
import HeaderComponent            from "/src/components/HeaderComponent.vue";
import { mapStores              } from 'pinia';
import { useJobSkillStore       } from '@/stores/jobSkillTable';
import { useCategoryLookupStore } from '@/stores/lookupTable';
import {
  Category,
  CategoryLookup,
  SelectableJobSkill,
} from "@/models/ApplicationForm"

interface Data {
  kioskMode: boolean
}

export default Vue.extend({
  name: "Page503",

  components: {
    HeaderComponent,
  },

  inject: [
    '$applicationFormService'
  ],

  data(): Data {
    return {
      kioskMode: false
    }
  },

  computed: {
    ...mapStores(
      useCategoryLookupStore,
      useJobSkillStore,
    ),
  },

  methods: {
    async checkLookupData() {
      if (this.categoryLookupStore.lookupTable.length !== 0) {
        this.goBack();
      }
      else {
        // Waits 15 seconds before trying again
        await new Promise(r => setTimeout(r, 15000));

        // Attempts to fetch lookup table data from API
        await this.getCategoryLookupData();

        // Recursively calls itself again to make another check
        this.checkLookupData();
      }
    },

    goBack(): void {
      if (this.kioskMode === true) {
        this.$router.replace('/welcome');
      }
      else {
        this.$router.replace('/');
      }
    },

    async getCategoryLookupData() {
      // Fetches the lookup table from the API and stores it
      this.categoryLookupStore.setLookupTable(
        await this.$applicationFormService.getLookupTable()
      );

      if (this.categoryLookupStore.lookupTable.length !== 0) {
        // Loads the job skills into the JobSkill store
        const selectableJobSkills: SelectableJobSkill[] =
          this.categoryLookupStore
          .getSortedCategoryOptions(Category.Skillset)
          .map(cat => {
            return {
              jobSkill:    { lookupId: cat.catLookupId },
              displayName: cat.optName,
              selected:    false
            }
          });
        this.jobSkillStore.setJobSkills(selectableJobSkills);
      }
    },

    reload() {
      location.reload();
    }
  },

  async mounted() {
    this.kioskMode = localStorage.getItem("KioskMode") === "true" ?? false;
    await this.getCategoryLookupData();
    this.checkLookupData();
  },


});
</script>

<style scoped>
@media (max-width: 850px) {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.2em;
  }
  p {
    font-size: 1.2em;
  }
}
@media (min-width: 851px) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    text-align: justify;
    font-size:  1.3em;
  }
}
</style>
