<template>
  <div style="width: 100%; height: 100%">
    <div class="px-4 text-center" style="max-width: 1200px; margin: 40px auto 40px auto;">
      <h1 class="mb-3 warning--text">
        Recent Application
      </h1>
      <h2 class="mb-5">
        You currently have a recent application on file
      </h2>
      <div v-if = "kioskMode === false">
        <p>
          You have a previous job application less than a year old on file. If you just
          applied please give us some time to look at your application. If you wish to
          amend your application, or have any questions please contact our
          Human Resources department by email at human.resources@wwinc.com, by phone at
          (573)614-4765, or in-person at 10411 Brenda St., Dudley, MO 63936.
        </p>
        <v-btn
          class = "mt-5"
          color  = "primary"
          @click = "gotoMainCompanySite"
        >
          Go To Main Site
        </v-btn>
      </div>
      <div v-else>
        <p class="text-center">
          If you just applied please give us some time to look at your application.
          If you wish to amend your application please speak with HR at the Personnel Window to
          receive an override code that you can enter below. You may also contact our Human Resources
          department by email at human.resources@wwinc.com or by phone at (573)614-4765.
        </p>
        <v-row class = "mt-md-16 mt-sm-8">
          <v-col sm="12" md="6" class="pr-md-10 text-sm-center text-md-right">
            <v-btn
              color  = "primary"
              @click = "routeTo('/welcome')"
            >
              Cancel Submission
            </v-btn>
          </v-col>
          <v-col sm="12" md="6" class="pl-md-10 text-sm-center text-md-left">
            <v-btn
              color     = "primary"
              :disabled = "!applicationStore.isFilledOut"
              @click    = "showOverrideModal = true"
            >
              Override Submission
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog
      v-model   = "showOverrideModal"
      max-width = "550px"
      persistent
    >
      <v-form
        ref="overrideForm"
        lazy-validation
      >
        <v-card class="py-3">
          <v-card-title class="text-h6">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Please enter the override code provided to you by HR
            </h4>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              v-model   = "overrideCode"
              label     = "Override Code"
              :rules    = "[v => !!v || 'Field is Required']"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="pt-0 mr-md-3">
            <h3
              v-if  = "errorMessage != ''"
              class = "pl-4 error--text"
              style = "display: inline"
            >
              {{ errorMessage }}
            </h3>
            <h3
              v-if  = "postingApplication"
              class = "pl-4 success--text"
              style = "display: inline"
            >
              Submitting...
            </h3>
            <v-spacer />
            <v-btn
              @click = "showOverrideModal = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              color  = "primary"
              @click = "submitApplicationOverride"
              text
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      v-model = "showSnackbar"
      color   = "error"
      timeout = "8000"
      :top    = "true"
    >
      <div class="text-center">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import {
  APIError,
  ApplicationResponse,
  EntireApplication
} from '@/models/ApplicationForm';

import { mapStores                } from 'pinia';
import { useEducationStore        } from '@/stores/educationTable';
import { useReferenceStore        } from '@/stores/referenceTable';
import { useFormerEmployerStore   } from '@/stores/formerEmployerTable';
import { useConvictionRecordStore } from '@/stores/convictionRecordTable';
import { useJobSkillStore         } from '@/stores/jobSkillTable';
import { useApplicationStore      } from '@/stores/applicationTable';

interface Data {
  showOverrideModal:  boolean,
  showSnackbar:       boolean,
  postingApplication: boolean,
  kioskMode:          boolean,
  overrideCode:       string,
  errorMessage:       string,
  snackbarMessage:    string,
  rerouteTimeout:     any
}

export default Vue.extend({
  name: "ApplicationSubmitRecentComponent",

  components: {
  },

  inject: [
    '$applicationFormService'
  ],

  data(): Data {
    return {
      showOverrideModal:  false,
      showSnackbar:       false,
      postingApplication: false,
      kioskMode:          false,
      overrideCode:       "",
      errorMessage:       "",
      snackbarMessage:    "",
      rerouteTimeout:     null
    }
  },

  computed: {
    ...mapStores(
      useApplicationStore,
      useEducationStore,
      useReferenceStore,
      useFormerEmployerStore,
      useConvictionRecordStore,
      useJobSkillStore,
    )
  },

  methods: {
    gotoMainCompanySite() {
      window.location.href = "https://www.wwwoodproducts.com/";
    },

    validateOverrideForm (): boolean {
      let vForm = this.$refs.overrideForm as Vue & {validate: () => boolean};

      if (vForm == undefined) {
        return false;
      }

      return vForm.validate();
    },

    /**
     * @name submitApplicationOverride
     * @desc Grabs the application data from the stores and sends it to the ApplicationFormService
     */
    async submitApplicationOverride() {

      // Only continues if the form is valid and not currently submitting application
      if (!this.validateOverrideForm() || this.postingApplication) {
        return;
      }

      // Only continues if an application in the store is filled out
      if (!this.applicationStore.isFilledOut) {
        this.routeTo("/welcome");
        return;
      }

      // Disables submit button to prevent multiple applications being submitted at once
      this.postingApplication = true;
      this.errorMessage = "";

      // Creates an object containing all the application data
      const entireApplication = {
        application:       this.applicationStore.application,
        references:        this.referenceStore.references,
        education:         this.educationStore.education,
        convictionRecords: this.convictionRecordStore.convictionRecords,
        formerEmployers:   this.formerEmployerStore.formerEmployers,
        jobSkills:         this.jobSkillStore.getSelectedJobSkills,
      } as EntireApplication

      console.log("Sending application to ApplicationFormService");

      // Calls the applicationFormService to contact the API
      const response: ApplicationResponse =
        await this
          .$applicationFormService
          .postApplicationOverride(entireApplication, this.overrideCode);

      // Routes to success page if API returns successful 200
      if (response.status === 200) {
        await this.$router.replace("/application-submit-success");
      }
      // Routes to the error page if there is no error response from the API.
      // This conditional should cover all non 400s
      else if (
        response.status !== 400  ||
        response.body   ==  null
      ) {
        console.log("The API returned an unknown error response");
        await this.$router.replace("/application-submit-error");
      }
      // Routes to the too recent page if the error response indicates the
      // API error was because the application is too recent
      else if (response.body.error === APIError.INVALID_OVERRIDE_PASSWORD) {
        this.errorMessage = "The override code is incorrect";
      }
      // Routes to the error page if the API gives another known error
      else {
        await this.$router.replace("/application-submit-error");
      }

      this.postingApplication = false;
    },

    routeTo(location: string) {
      this.$router.replace(location);
    }
  },

  mounted() {
    this.kioskMode = localStorage.getItem("KioskMode") === "true" ?? false;
    if (this.kioskMode === true) {
      if (!this.applicationStore.isFilledOut) {
        this.snackbarMessage = "No application data found, unable to override";
        this.showSnackbar = true;
      }

      // Routes back to the main page after 10 minutes
      this.rerouteTimeout = setTimeout(() =>
        this.routeTo("/welcome"),
        10 * 60 * 1000
      );
    }
  },

  beforeDestroy() {
    clearTimeout(this.rerouteTimeout);
  }

});
</script>

<style scoped>
@media (max-width: 850px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    font-size: 1.2em;
  }
}
@media (min-width: 851px) {
  h1 {
    font-size: 3.5em;
  }
  h2 {
    font-size: 1.7em;
  }
  p {
    text-align: justify;
    font-size:  1.3em;
  }
}
</style>
