<template>
  <v-app-bar
    color     = "primary"
    height    = "76px"
    elevation = "0"
    dark
  >
    <img
      v-if  = "$vuetify.breakpoint.width > 629"
      :src  = "require('/src/assets/WWLogoWhite.png')"
      style = "width: 130px"
    />
    <img
      v-else
      :src  = "require('/src/assets/WWLogoWhite.png')"
      style = "width: 110px"
    />
    <v-spacer />
    <div
      v-if  = "$vuetify.breakpoint.width > 629"
      class = "white--text text-right"
      style = "font-size: 26px;"
    >
      {{ title }}
    </div>
    <div
      v-else
      class = "white--text text-right"
      style = "font-size: 19px;"
    >
      {{ title }}
    </div>
    <div
      v-if  = "$vuetify.breakpoint.width > 799"
      style = "width: 130px"
    ></div>
    <v-spacer v-if = "$vuetify.breakpoint.width > 799" />
  </v-app-bar>
</template>


<script lang="ts">
import Vue from "vue";


export default Vue.extend({
  name: "HeaderComponent",

  props: {
    title: {
      type:     String,
      required: true,
    },
  },

  data() {
    return {
    }
  },

  methods: {
  },

});
</script>

<style>

</style>
