<template>
  <div class="bg-image">
    <div
      class = "d-flex flex-column justify-start align-center"
      style = "width: 100%; height: 100%; position: absolute; padding-top: 10vh"
    >
      <div class = "text-background px-8 pt-5 pb-2 mx-2">
        <h1 class="white--text text-center">
          Employment Application
        </h1>
        <p class="white--text text-center pt-3">
          Welcome! Thank you for your interest in joining W. W. Wood Products Inc. If you recently filled
          out an application, please give us time to process it before applying again.
        </p>
      </div>
      <v-btn
        class  = "mt-12 pa-6 pa-md-8 application-button font-weight-bold"
        @click = "routeTo('/')"
      >
        Start Application
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Welcome",

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
    routeTo(location: string) {
      this.$router.push(location);
    }
  },

});
</script>

<style scoped>
.bg-image {
  height:              100%;
  background-image:    url("~@/assets/wwcampus.jpg");
  background-position: center;
  background-repeat:   no-repeat;
  background-size:     cover;
}

.text-background {
  backdrop-filter: blur(4px);
  border-radius:   8px;
  max-width:       1000px;
  background:      rgba(0,0,0,0.5)
}

.application-button {
  text-transform:  none;
  backdrop-filter: blur(5px);
  background:      rgba(33, 96, 151, 0.85) !important;
}

@media (max-width: 850px) {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.25em;
  }
  p {
    font-size: 1em;
  }
  .application-button {
    font-size: 20px;
  }
}
@media (min-width: 851px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.7em;
  }
  p {
    text-align: justify;
    font-size:  1.3em;
  }
  .application-button {
    font-size: 22px;
  }
}
</style>
