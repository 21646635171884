import {
  JobSkill,
  SelectableJobSkill,
} from '@/models/ApplicationForm';
import { defineStore } from 'pinia';

interface IState {
  jobSkills: SelectableJobSkill[]
}

export const useJobSkillStore = defineStore('jobSkill', {

  state: (): IState => ({
    jobSkills: []
  }),

  actions: {
    setJobSkills (jobSkills: SelectableJobSkill[]) {
      this.jobSkills = jobSkills;
    },

    resetSelectedJobSkills() {
      this.jobSkills.map(jobSkill => jobSkill.selected = false);
    },
  },

  getters: {
    getSelectedJobSkills(): JobSkill[] {
      return this.jobSkills
        .filter(jobSkill => jobSkill.selected)
        .map(jobSkill => jobSkill.jobSkill);
    },

    getSelectableJobSkill() {
      return (lookupId: number): SelectableJobSkill | undefined => {
        return this.jobSkills.find( jobSkill =>
          jobSkill.jobSkill.lookupId === lookupId
        );
      };
    },
  },

});
