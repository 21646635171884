import { FormerEmployer } from '@/models/ApplicationForm';
import { defineStore } from 'pinia';

interface IState {
  formerEmployers: FormerEmployer[];
}

export const useFormerEmployerStore = defineStore('formerEmployer', {

  state: (): IState => ({
    formerEmployers: []
  }),

  actions: {
    addFormerEmployer (formerEmployer: FormerEmployer) {
      this.formerEmployers.push(formerEmployer);
    },

    updateFormerEmployer (index: number, updatedFormerEmployer: FormerEmployer) {
      if (0 <= index && index < this.formerEmployers.length) {
        this.formerEmployers.splice(index, 1, updatedFormerEmployer);
      }
    },

    removeFormerEmployer (indexToRemove: number) {
      if (0 <= indexToRemove && indexToRemove < this.formerEmployers.length) {
        this.formerEmployers.splice(indexToRemove, 1);
      }
    },

  },

});
