import Vue     from 'vue'
import App     from './App.vue'
import router  from './router'
import vuetify from './plugins/vuetify'
// @ts-ignore  - VueTheMask does not have TypeScript support
import VueMask from 'v-mask'

import { ApplicationFormService } from './services/ApplicationFormService'
import {
  createPinia,
  PiniaVuePlugin
} from 'pinia'

Vue.config.productionTip = false

const apiUrl = process.env.VUE_APP_API_URL;

// Disables logging on production
if (process.env.NODE_ENV === "production") {
  console.log   = function () {};
  console.warn  = function () {};
  console.error = function () {};
  Vue.config.devtools = false;
  Vue.config.silent   = true;
}

const pinia = createPinia();

const $applicationFormService = new ApplicationFormService(apiUrl);

Vue.prototype.$applicationFormService = $applicationFormService;

Vue.use(PiniaVuePlugin);
Vue.use(VueMask);

new Vue({
  router,
  vuetify,
  pinia,
  provide () {
    return {
      $applicationFormService: $applicationFormService
    }
  },
  render: h => h(App),
}).$mount('#app')
