<template>
  <div>
    <v-row>
      <v-col>
        <h3 class="d-inline mr-2">
          Job Related Skills
        </h3>
        <h5 class="d-inline">
          (Check all that apply to you)
        </h5>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row flex-wrap">
      <v-col sm="6" md="3"
        v-for = "jobSkill in jobSkillStore.jobSkills"
        :key  = jobSkill.jobSkill.lookupId
        class = "py-0"
      >
        <v-checkbox
          v-model = jobSkill.selected
          :label  = jobSkill.displayName
          class   = "ma-0"
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapStores              } from 'pinia';
import { useJobSkillStore       } from '@/stores/jobSkillTable';
import { useCategoryLookupStore } from '@/stores/lookupTable';
import {
  SelectableJobSkill,
  CategoryLookup,
  Category,
} from "@/models/ApplicationForm";

export default Vue.extend({
  name: "JobRelatedSkillsComponent",

  data() {
    return {
    }
  },

  computed: {
    ...mapStores (
      useJobSkillStore,
      useCategoryLookupStore,
    )
  },

  methods: {
  },

});
</script>

<style scoped>
</style>
