var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.referenceTableHeaders,"items":_vm.referenceStore.references,"mobile-breakpoint":"1000","no-data-text":"Please add three references","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","rounded":""}},[_c('v-toolbar-title',[_vm._v(" References ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.newReferenceModal()}}},[_vm._v(" Add Reference ")]),_c('v-dialog',{attrs:{"max-width":"1100px","persistent":""},model:{value:(_vm.showReferenceModal),callback:function ($$v) {_vm.showReferenceModal=$$v},expression:"showReferenceModal"}},[_c('v-form',{ref:"referenceForm",attrs:{"lazy-validation":""}},[_c('v-card',[_c('v-card-title',{staticClass:"primary py-3"},[_c('h2',{staticClass:"my-0 ml-3"},[_vm._v(" "+_vm._s(`Reference #${_vm.referenceNumberDisplay}`)+" ")])]),_c('v-card-text',{staticClass:"mt-5"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Reference Name","rules":_vm.genericFieldRule(64),"dense":""},model:{value:(_vm.tempReference.name),callback:function ($$v) {_vm.$set(_vm.tempReference, "name", $$v)},expression:"tempReference.name"}})],1),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Reference Address","rules":_vm.genericFieldRule(128),"dense":""},model:{value:(_vm.tempReference.address),callback:function ($$v) {_vm.$set(_vm.tempReference, "address", $$v)},expression:"tempReference.address"}})],1),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-####'),expression:"'###-###-####'"}],attrs:{"inputmode":"tel","label":"Reference Phone Number (Optional)","rules":[v => (
                          v == null
                          || v === ''
                          || v.length === 12
                        ) || 'Not a valid phone number'
                      ],"dense":""},model:{value:(_vm.tempReference.phoneNumber),callback:function ($$v) {_vm.$set(_vm.tempReference, "phoneNumber", $$v)},expression:"tempReference.phoneNumber"}})],1),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Reference Employment Position","rules":_vm.genericFieldRule(50),"dense":""},model:{value:(_vm.tempReference.jobTitle),callback:function ($$v) {_vm.$set(_vm.tempReference, "jobTitle", $$v)},expression:"tempReference.jobTitle"}})],1),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"inputmode":"numeric","label":"Years Acquainted","rules":[
                        v => !!v || 'Field is Required'
                      ],"dense":""},model:{value:(_vm.tempReference.yearsAcquainted),callback:function ($$v) {_vm.$set(_vm.tempReference, "yearsAcquainted", $$v)},expression:"tempReference.yearsAcquainted"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mx-sm-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"text","text":""},on:{"click":function($event){return _vm.closeReferenceModal()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.saveReferenceModal()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showReferenceDeleteModal),callback:function ($$v) {_vm.showReferenceDeleteModal=$$v},expression:"showReferenceDeleteModal"}},[_c('v-card',{staticClass:"py-3"},[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_c('h4',{staticClass:"text-center",staticStyle:{"word-break":"break-word"}},[_vm._v(" Are you sure you want to delete this Reference? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.hideDeleteReferenceModal()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteReference()}}},[_vm._v(" Delete ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"ml-1 mr-1",on:{"click":function($event){return _vm.editReferenceModal(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteReferenceModal(item)}}},[_vm._v(" mdi-delete ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }