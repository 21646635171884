import {
  CategoryLookup,
  Category,
} from '@/models/ApplicationForm';
import { defineStore } from 'pinia';

interface IState {
  lookupTable: CategoryLookup[]
}

export const useCategoryLookupStore = defineStore('categoryLookup', {

  state: (): IState => ({
    lookupTable: []
  }),

  // Populates the lookup table
  actions: {
    setLookupTable (table: CategoryLookup[]) {
      this.lookupTable = table;
    },
  },

  getters: {
    // Returns all category options based on a given Category type (catId)
    getCategoryOptions() {
      return (category: Category): CategoryLookup[] => {
        return this.lookupTable.filter( record =>
           record.catId === category
        );
      };
    },

    // Returns a sorted list of category options based on a given Category type (catId)
    getSortedCategoryOptions() {
      return (category: Category): CategoryLookup[] => {

        const sortByOrderFun = (a: CategoryLookup, b: CategoryLookup) => a.order - b.order;

        return this.lookupTable
          .filter( record => record.catId  === category)
          .sort(sortByOrderFun);
      };
    },

    // Returns a specific category option based on a given categoryLookupId
    getCategoryFromId() {
      return (id: number): CategoryLookup | null => {

        const result = this.lookupTable.find( record => record.catLookupId === id);

        if (result === undefined)
          return null;
        else
          return result;
      };
    },
  },

});
