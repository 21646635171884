<template>
  <v-app>
    <v-main>
      <router-view />
      <footer-component />
    </v-main>
  </v-app>

</template>

<script lang="ts">
import Vue from 'vue';
import FooterComponent  from '@/components/FooterComponent.vue';


export default Vue.extend({
  name: 'App',
    components: {
      FooterComponent,
    },

    data: () => ({
    }),

    beforeMount() {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$vuetify.theme.dark = true;
      }
    },
});
</script>

<style>
.theme--dark.v-application {
  background-color: var(--v-background-base) !important;
}

.theme--light.v-application {
  background-color: var(--v-background-base) !important;
}

h1 {
  color: var(--v-textPri-base) !important;
}

h2 {
  color: var(--v-textSec-base) !important;
}

h3 {
  margin-top:    6px;
  margin-bottom: 6px;
  color: var(--v-textSec-base) !important;
}

h4 {
  color: var(--v-textSec-base) !important;
}

h5 {
  color: var(--v-textTri-base) !important;
}

p {
  color: var(--v-text-base) !important;
}

a {
  color: var(--v-accent-base) !important;
}

.v-radio {
  transform: scale(1.15);
}

.v-divider {
  background-color: grey;
}

</style>
