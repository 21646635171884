<template>
  <div>
    <header-component title="Application for Employment" />
    <application-form-component />
  </div>
</template>

<script lang="ts">
import Vue                          from "vue";
import HeaderComponent              from "/src/components/HeaderComponent.vue";
import ApplicationFormComponent     from "/src/components/applicationForm/ApplicationFormComponent.vue";
import { mapStores                } from 'pinia';
import { useJobSkillStore         } from '@/stores/jobSkillTable';
import { useCategoryLookupStore   } from '@/stores/lookupTable';
import { useConvictionRecordStore } from '@/stores/convictionRecordTable';
import { useEducationStore        } from '@/stores/educationTable';
import { useFormerEmployerStore   } from '@/stores/formerEmployerTable';
import { useReferenceStore        } from '@/stores/referenceTable';


import {
  Category,
  SelectableJobSkill,
} from "@/models/ApplicationForm"

export default Vue.extend({
  name: "ApplicationForm",

  components: {
    HeaderComponent,
    ApplicationFormComponent
  },

  inject: [
    '$applicationFormService'
  ],

  computed: {
    ...mapStores (
      useJobSkillStore,
      useCategoryLookupStore,
      useConvictionRecordStore,
      useEducationStore,
      useFormerEmployerStore,
      useReferenceStore
    ),
  },

  methods: {
    async getCategoryLookup() {
      // Fetches the lookup table from the API and stores it
      if (this.categoryLookupStore.lookupTable.length === 0) {
        this.categoryLookupStore.setLookupTable(
          await this.$applicationFormService.getLookupTable()
        );
      }

      // Routes to the 503 page if the lookup table data wasn't fetched
      if (this.categoryLookupStore.lookupTable.length === 0) {
        this.$router.replace('/503');
      }
      else {
        // Loads the job skills into the JobSkill store
        const selectableJobSkills: SelectableJobSkill[] =
          this.categoryLookupStore
            .getSortedCategoryOptions(Category.Skillset)
            .map(cat => {
              return {
                jobSkill:    { lookupId: cat.catLookupId },
                displayName: cat.optName,
                selected:    false
              }
            });
        this.jobSkillStore.setJobSkills(selectableJobSkills);
      }
    },
  },

  async mounted() {
    await this.getCategoryLookup();

    // Resets the store states
    this.jobSkillStore.resetSelectedJobSkills();
    this.convictionRecordStore.convictionRecords = [];
    this.educationStore.education                = [];
    this.formerEmployerStore.formerEmployers     = [];
    this.referenceStore.references               = [];
  },

});
</script>

<style scoped>

</style>
