import { Reference } from '@/models/ApplicationForm';
import { defineStore } from 'pinia';

interface IState {
  references: Reference[];
}

export const useReferenceStore = defineStore('reference', {

  state: (): IState => ({
    references: []
  }),

  actions: {
    addReference (reference: Reference) {
      this.references.push(reference);
    },

    updateReference (index: number, updatedReference: Reference) {
      if (0 <= index && index < this.references.length) {
        this.references.splice(index, 1, updatedReference);
      }
    },

    removeReference (indexToRemove: number) {
      if (0 <= indexToRemove && indexToRemove < this.references.length) {
        this.references.splice(indexToRemove, 1);
      }
    },

  },

});
