<template>
  <div>
    <header-component title="Application On File" />
    <application-submit-recent-component />
  </div>
</template>

<script lang="ts">
import HeaderComponent                  from "/src/components/HeaderComponent.vue";
import ApplicationSubmitRecentComponent from "/src/components/applicationForm/ApplicationSubmitRecentComponent.vue";

export default {
  name: "ApplicationSubmitRecent",

  components: {
    HeaderComponent,
    ApplicationSubmitRecentComponent
  }
}
</script>

<style scoped>

</style>
