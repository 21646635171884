<template>
  <div>
    <header-component title="404 Page" />
    <div class="my-5 text-center">
      <h1 class="pt-5 mb-5 warning--text">
        404 Page Not Found
      </h1>
      <v-btn
        class = "mt-5"
        color  = "primary"
        @click = "goBack"
      >
        Go Back
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue             from "vue";
import HeaderComponent from "/src/components/HeaderComponent.vue";


export default Vue.extend({
  name: "Page404",

  components: {
    HeaderComponent,
  },

  data() {
    return {
      kioskMode: false
    }
  },

  methods: {
    goBack() {
      if (this.kioskMode === true) {
        this.$router.replace('/welcome');
      }
      else {
        this.$router.replace('/');
      }
    }
  },

  async mounted() {
    this.kioskMode = localStorage.getItem("KioskMode") === "true" ?? false;
  }

});
</script>

<style scoped>
@media (max-width: 850px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    font-size: 1.2em;
  }
}
@media (min-width: 851px) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    text-align: justify;
    font-size:  1.3em;
  }
}
</style>
