<template>
  <div class="px-4 text-center" style="max-width: 1200px; margin: 40px auto 40px auto;">
    <h1 class="mb-3 error--text">
      Submission Error
    </h1>
    <h2 class="mb-5">
      There was an error while processing your application
    </h2>
    <div v-if = "kioskMode === false">
      <p class="text-center">
        If you have any questions, you can contact the Human
        Resources Department by email at human.resources@wwinc.com or phone at (573)614-4765.
        Sorry for any inconvenience.
      </p>
    </div>
    <div v-else>
      <p class="text-center">
        Please speak with HR at the Personnel Window and let them know there
        was an error with submitting your application.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

interface Data {
  kioskMode: boolean
}

export default Vue.extend({
  name: "ApplicationSubmitSuccessComponent",

  components: {
  },

  inject: [
    '$applicationFormService'
  ],

  data(): Data {
    return {
      kioskMode: false
    }
  },

  async mounted() {
    this.kioskMode = localStorage.getItem("KioskMode") === "true" ?? false;
    if (this.kioskMode === true) {
      await new Promise(r => setTimeout(r, 45000));
      this.$router.replace('/welcome');
    }
  }

});
</script>

<style scoped>
@media (max-width: 850px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    font-size: 1.2em;
  }
}
@media (min-width: 851px) {
  h1 {
    font-size: 3.5em;
  }
  h2 {
    font-size: 1.7em;
  }
  p {
    text-align: justify;
    font-size:  1.3em;
  }
}
</style>
