<template>
  <div>
    <header-component title="Kiosk Mode Disabled" />
    <div class="my-5 text-center">
      <h1 class="pt-md-5 mb-5">
        Kiosk Mode Disabled
      </h1>
      <h2 class="mb-3">
        Redirecting to application page shortly...
      </h2>
      <div>
        <v-btn
          class = "mt-5"
          color  = "primary"
          @click = "gotoApplicationPage"
        >
          Continue
        </v-btn>
      </div>
      <div>
        <v-btn
          class = "mt-5"
          color  = "primary"
          @click = "gotoEnableKiosk"
        >
          Enable Kiosk Mode
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue             from "vue";
import HeaderComponent from "/src/components/HeaderComponent.vue";

export default Vue.extend({
  name: "KioskModeDisable",

  components: {
    HeaderComponent
  },

  data() {
    return {
    }
  },

  mounted() {
    localStorage.setItem("KioskMode", "false");
    this.gotoApplicationDelay();
  },

  methods: {
    async gotoApplicationDelay() {
      await new Promise(r => setTimeout(r, 30000));
      this.$router.replace('/');
      this.$router.go(0);
    },

    gotoApplicationPage() {
      this.$router.replace('/');
      this.$router.go(0);
    },

    gotoEnableKiosk() {
      this.$router.replace('/kiosk');
      this.$router.go(0);
    }
  },

});
</script>

<style scoped>
@media (max-width: 850px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    font-size: 1.2em;
  }
}
@media (min-width: 851px) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    text-align: justify;
    font-size:  1.3em;
  }
}
</style>
