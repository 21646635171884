<template>
  <div class="px-4" style="max-width: 1200px; margin: 40px auto 40px auto">
    <v-form ref="applicationForm" lazy-validation>
      <!-- --------------------------- Personal Information Section --------------------------- -->
      <v-row class="mb-2">
        <v-col>
          <h1>Personal Information</h1>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3 class="mb-0">
            Name
          </h3>
        </v-col>
        <v-col sm="12" md="4">
          <v-text-field
            v-model = "application.firstName"
            label   = "First"
            ref     = "First Name"
            :rules  = "genericFieldRule(32)"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="4">
          <v-text-field
            v-model = "application.middleName"
            label   = "Middle"
            ref     = "Middle Name"
            :rules  = "genericFieldRule(32)"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="4">
          <v-text-field
            v-model = "application.lastName"
            label   = "Last"
            ref     = "Last Name"
            :rules  = "genericFieldRule(32)"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3 class="mb-0">
            Address
          </h3>
        </v-col>
        <v-col sm="12" md="5">
          <v-text-field
            v-model = "application.address.street"
            label   = "Street"
            ref     = "Street"
            :rules  = "genericFieldRule(50)"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="3">
          <v-text-field
            v-model = "application.address.city"
            label   = "City"
            ref     = "City"
            :rules  = "genericFieldRule(32)"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="2">
          <v-select
            v-model    = "application.address.state"
            :items     = "getStates()"
            item-text  = "value"
            item-value = "key"
            label      = "State"
            ref        = "State"
            :rules = "[
              v => !!v || 'Field is Required',
            ]"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col sm="12" md="2">
          <v-text-field
            v-model   = "application.address.zipcode"
            v-mask    = "'#####'"
            inputmode = "numeric"
            label     = "Zipcode"
            ref       = "Zipcode"
            :rules = "[
              v => !!v || 'Field is Required',
              v => (v && v.length === 5) || 'Not a valid Zipcode'
            ]"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3 class="mb-0">
            Contact Information
          </h3>
        </v-col>
        <v-col sm="12" md="6" class="pr-md-2">
          <v-text-field
            v-model   = "application.phoneNumber"
            v-mask    = "'###-###-####'"
            inputmode = "tel"
            label     = "Phone Number"
            ref       = "Phone Number"
            :rules    = "[
              v => !!v || 'Field is Required',
              v => (v && v.length === 12) || 'Not a valid phone number'
            ]"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="6" class="pl-md-2">
          <v-text-field
            v-model   = "application.emailAddress"
            label     = "Email Address"
            inputmode = "email"
            ref       = "Email Address"
            :rules    = "[
              v => !!v || 'Field is Required',
              v => /.+@.+\..+/.test(v) || 'Must contain a valid E-mail',
              v => (v && v.length <= 50) || 'Field must be less than 51 characters',
            ]"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="6" class="pr-md-2">
          <h3>Social Security Number</h3>
          <v-text-field
            v-model   = "application.socialSecurity"
            v-mask    = "'###-##-####'"
            label     = "Social Security Number"
            inputmode = "numeric"
            ref       = "Social Security Number"
            :rules    = "[
              v => !!v || 'Field is Required',
              v => (v && v.length === 11) || 'Not a valid SSN'
            ]"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="6" class="pl-md-5">
          <h3>Are you 18 years of age or older?</h3>
          <v-radio-group
            v-model = "application.over18"
            ref     = "Over 18 Years of Age"
            :rules  = "radioRequired()"
            class   = "mt-0 pt-2 pl-1"
            row
          >
            <v-radio
              label  = "Yes"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "No"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="6">
          <h3>Referral Source</h3>
          <v-select
            v-model    = "application.referralSourceId"
            :items     = "getCategory(Category.ReferralSource)"
            item-title = "title"
            item-value = "value"
            label      = "Referral Source"
            ref        = "Referral Source"
            :rules     = "[v => !!v || 'Field is Required']"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          v-if  = "application.referralSourceId != null"
          sm    = "12"
          md    = "6"
          class = "pl-md-2"
        >
          <h3>Referral Name</h3>
          <v-text-field
            v-model = "application.referralName"
            label   = "Name of Referral (Optional)"
            ref     = "Referral Name"
            :rules  = "[v => (
              v == null ||
              v.length <= 50
            ) || 'Field must be less than 51 characters']"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Fake honeypot field for bot detection -->
      <v-row
        class = "pet-css"
        style = "width: 95%"
        dense
      >
        <v-col sm="12" md="12">
          <h3 class="mb-0">
            What is the name of your favorite pet?
          </h3>
        </v-col>
        <v-col sm="12" md="4">
          <v-text-field
            v-model = "petName"
            label   = "Name of Favorite Pet"
            ref     = "Pet Name"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="my-12"></v-divider>

      <!-- --------------------------- Employment Information Section --------------------------- -->
      <v-row class="mb-2">
        <v-col>
          <h1>Employment Information</h1>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="6" class="pr-md-2">
          <h3>Employment Position Desired</h3>
          <v-text-field
            v-model = "application.employmentPosition"
            label   = "Position Title"
            ref     = "Position Title"
            :rules  = "genericFieldRule(50)"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="6" class="pl-md-5">
          <h3>Full Time or Part Time?</h3>
          <v-radio-group
            v-model = "application.fullTime"
            ref     = "Full Time or Part Time"
            :rules  = "radioRequired()"
            class   = "mt-0 pt-2 pl-1"
            row
          >
            <v-radio
              label  = "Full Time"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "Part Time"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="6" class="pr-md-2">
          <h3>Date You Can Start</h3>
          <v-menu
            v-model                 = "showStartDatePicker"
            :close-on-content-click = "false"
            :nudge-right            = "40"
            transition              = "scale-transition"
            offset-y min-width      = "auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value             = "formatDate(application.startDate)"
                label              = "Start Date"
                prepend-inner-icon = "mdi-calendar"
                ref                = "Start Date"
                :rules             = "[v => !!v || 'Field is Required']"
                v-bind             = "attrs"
                v-on               = "on"
                readonly
                dense
                outlined
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model = "application.startDate"
              @input  = "showStartDatePicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          sm    = "12"
          md    = "6"
          class = "pl-md-4"
        >
          <h3>Salary Desired</h3>
          <v-text-field
            v-model = "application.salaryDesired"
            label   = "Salary Desired"
            ref     = "Salary Desired"
            :rules  = "genericFieldRule(64)"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3>Have You Worked For W.W. Wood Products Before?</h3>
          <v-radio-group
            v-model = "application.hasWorkedHereBefore"
            ref     = "Has Worked Here Before"
            :rules  = "radioRequired()"
            class   = "mt-0 pt-2 pl-1"
            row
          >
            <v-radio
              label  = "Yes"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "No"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-divider class="my-12"></v-divider>

      <!-- --------------------------- Previous Employment Section --------------------------- -->
      <v-row class="mb-2">
        <v-col>
          <h1>Previous Employment</h1>
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col sm="12" md="12">
          <h4 class="mb-1 ml-2">
            Please include your four most recent employers if you have any
          </h4>
          <FormerEmployerTableComponent
            :has-former-employer-history     = "application.hasFormerEmployerHistory"
            @hasFormerEmployerHistoryUpdated = "setFormerEmployerHistory"
          />
        </v-col>
      </v-row>

      <div v-if="this.application.hasFormerEmployerHistory">
        <v-row dense>
          <v-col
            sm    = "12"
            md    = "6"
            class = "pr-md-4"
          >
            <h3>Are You Currently Employed?</h3>
            <v-radio-group
              v-model = "application.currentlyEmployed"
              ref     = "Currently Employed"
              :rules  = "radioRequired()"
              class   = "mt-0 pt-2 pl-1"
              row
            >
              <v-radio
                label  = "Yes"
                :value = "true"
                class  = "mr-8"
              ></v-radio>
              <v-radio
                label  = "No"
                :value = "false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            v-if  = "application.currentlyEmployed"
            sm    = "12"
            md    = "6"
            class = "pl-md-4"
          >
            <h3>May We Inquire of Your Present Employer?</h3>
            <v-radio-group
              v-model = "application.inquirePresentEmployer"
              ref     = "Inquire Present Employer"
              :rules  = "radioRequired()"
              class   = "mt-0 pt-2 pl-1"
              row
            >
              <v-radio
                label  = "Yes"
                :value = "true"
                class  = "mr-8"
              ></v-radio>
              <v-radio
                label  = "No"
                :value = "false"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col sm="12" md="12">
            <h3>Have You Ever Been Involuntarily Discharged From a Job?</h3>
            <v-radio-group
              v-model = "application.involuntarilyDischarged"
              ref     = "Involuntarily Discharged"
              :rules  = "radioRequired()"
              class   = "mt-0 pt-2 pl-1"
              row
            >
              <v-radio
                label  = "Yes"
                :value = "true"
                class  = "mr-8"
              ></v-radio>
              <v-radio
                label  = "No"
                :value = "false"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row dense v-if="application.involuntarilyDischarged">
          <v-col sm="12" md="12">
            <h3>Please Explain Your Involuntary Discharge and Give Dates</h3>
            <v-textarea
              v-model = "application.explanationForDischarge"
              ref     = "Explanation For Discharge"
              :rules  = "genericFieldRule(512)"
              rows    = "3"
              outlined
              auto-grow
              clearable
              ></v-textarea>
          </v-col>
        </v-row>
      </div>

      <v-divider class="my-12"></v-divider>

      <!-- ------------------------------ Education Section ------------------------------ -->
      <v-row class="mb-2">
        <v-col>
          <h1>Education</h1>
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col sm="12" md="12">
          <h4 class="mb-1 ml-2">
            Please include every high school, college, and trade school attended
          </h4>
          <EducationTableComponent />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3>Job Certifications and Job Related Skills</h3>
          <v-textarea
            v-model = "application.jobCertifications"
            ref     = "Job Certifications"
            :rules  = "[v => (
              v == null ||
              v.length <= 1024
            ) || 'Field must be less than 1025 characters']"
            rows    = "3"
            outlined
            auto-grow
            clearable
          ></v-textarea>
        </v-col>
      </v-row>

      <!--
        CURRENTLY HIDDEN in UI as Ken Rinehart changed his mind about the feature
        of selecting relevant Job Related Skills.
        Code for this feature might be deleted in the future if the feature
        is never requested to be re-enabled.
      <v-row>
        <v-col sm="12" md="12">
          <JobRelatedSkillsComponent></JobRelatedSkillsComponent>
        </v-col>
      </v-row>

      <v-row
        v-if = "isOtherJobSkillSelected"
        dense
      >
        <v-col sm="12" md="12">
          <h3>Other Job Related Skills</h3>
          <v-text-field
            v-model = "application.otherJobSkills"
            label   = "Other Job Skills"
            :rules  = "genericFieldRule(128)"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="my-12"></v-divider>
      -->

      <!-- --------------------------- General Information Section --------------------------- -->
      <v-row class="mb-2">
        <v-col>
          <h1>General Information</h1>
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col sm="12" md="12">
          <h4 class="mb-1 ml-2">
            List three persons not related to you, whom you have known at least one year
          </h4>
          <ReferenceTableComponent></ReferenceTableComponent>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3>Membership in Professional or Civic Organizations</h3>
          <h5
            style = "text-align: justify"
            class = "mb-2 mb-md-1"
          >
            Exclude Organizations, the Name/Character of which may disclose your
            race, sex, color, religion, disability, age, national origin,
            or other protected status
          </h5>
          <v-textarea
            v-model = "application.organizationMembership"
            ref     = "Organization Membership"
            :rules  = "[v => (
              v == null ||
              v.length <= 1024
            ) || 'Field must be less than 1025 characters']"
            rows    = "3"
            outlined
            auto-grow
            clearable
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row dense>
        <h3 class="mb-2">
          If you are offered employment with our company you will need two specific
          forms of identification. Do you possess:
        </h3>
      </v-row>
      <v-row dense>
        <v-col sm="12" md="6">
          <p class="mb-1">
            A valid State Driver's License or Identification Card
          </p>
          <v-radio-group
            v-model = "application.hasPhotoId"
            ref     = "Has Driver's License or Identification Card"
            :rules  = "radioRequired()"
            class   = "mt-0 pl-1"
            row
          >
            <v-radio
              label  = "Yes"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "No"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col sm="12" md="6">
          <p class=mb-1>
            An official Social Security Card
          </p>
          <v-radio-group
            v-model = "application.hasPhysicalSsnCard"
            ref     = "Has Official Social Security Card"
            :rules  = "radioRequired()"
            class   = "mt-0 pl-1"
            row
          >
            <v-radio
              label  = "Yes"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "No"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>


      <v-row dense>
        <v-col sm="12" md="12">
          <h3 style="text-align: left">
            Are You Willing to Take a Physical Examination and/or Drug Test
            at our Expense upon a Conditional Offer of Employment?
          </h3>
          <v-radio-group
            v-model = "application.examinationAndDrugTest"
            ref     = "Examination and Drug Test"
            :rules  = "radioRequired()"
            class   = "mt-0 pt-2 pl-1"
            row
          >
            <v-radio
              label  = "Yes"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "No"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3 style="text-align: left">
            Have you ever been convicted of or pleaded guilty to a misdemeanor or felony
            (Other than a parking violation)? You are not obligated to disclose sealed
            or expunged records of conviction.
          </h3>
          <h5
            class = "mb-2"
            style = "text-align: left"
          >
            A "Yes" response to this question will not necessarily disqualify you from
            consideration for employment with our company. The nature and circumstances of any
            conviction, how long ago it occurred, and other factors, including the relevancy of
            the conviction to the position for which you are applying, are all important in the
            employment consideration. Thus, please provide a complete response to this question
            so that an appropriate decision may be made.
          </h5>
          <v-radio-group
            v-model = "application.convictedOfMisdemeanorOrFelony"
            ref     = "Convicted of Misdemeanor or Felony"
            :rules  = "radioRequired()"
            class   = "mt-0 pt-2 pl-1"
            row
          >
            <v-radio
              label  = "Yes"
              :value = "true"
              class  = "mr-8"
            ></v-radio>
            <v-radio
              label  = "No"
              :value = "false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row
        class = "mb-5"
        v-if  = "application.convictedOfMisdemeanorOrFelony"
      >
        <v-col sm="12" md="12">
          <h4 class="mb-1 ml-2">
            Please provide the following information for every conviction or plea
          </h4>
          <ConvictionRecordTableComponent />
        </v-col>
      </v-row>

      <v-divider class="my-12"></v-divider>

      <!-- --------------------- Acknowledgement and Authorization Section --------------------- -->
      <v-row class="mb-2">
        <v-col>
          <h1>Acknowledgement and Authorization</h1>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <p style="text-align: justify">
            I understand and agree that, if hired, my employment is for no definite period and can
            be terminated at any time with or without notice, with or without cause by either myself
            or W. W. Wood Products, Inc. In the event that I am employed, I understand that regardless
            of the shift and job that I am first employed, I may be required to accept a change of job
            or shift depending on my demonstrated skills after employment and the needs of
            W. W. Wood Products, Inc. I understand that I must meet the standards established by
            W. W. Wood Products, Inc. for my job classification as a condition of initial and continued
            employment, which may be determined by a physical examination and/or a drug test.
            I understand also, that if employed, I am required to abide by all rules and regulations
            of W. W. Wood Products, Inc. I understand that no supervisor, officer, agent, or
            representative of W. W. Wood Products, Inc. other than its President, has any authority to
            enter into any agreement for employment for any period of time, or to make any agreement
            contrary to the foregoing. In addition, I understand and agree that this application shall
            be valid for a period of one year. If I wish to be considered after thirty (30) days, I
            recognize that I must complete a new application for employment.
          </p>
          <p style="text-align: justify">
            I grant permission to W. W. Wood Products, Inc. to investigate my personal, educational,
            and work histories thoroughly. In addition, I authorize W. W. Wood Products, Inc. to confirm
            all information that I have given in connection with my application for employment and to
            obtain information and/or a report from any state agency or any other entity which may include
            both general and personal information about me. I, furthermore, release W. W. Wood Products, Inc.
            and its agents from liability for any acts or omissions occurring during either such
            investigation or confirmation, or both. I further release any one or more of individuals,
            organizations and their agents, educational institutions that I attended and their agents,
            or my former employers and their agents from any liability for any acts or omissions occurring
            in its or their responses to W. W. Wood Products, Inc.’s inquiries about me. This release
            specifically covers the employers and their agents and the educational institutions and their
            agents that I have identified in my responses to the inquiries made on this application form.
            I understand and agree that W. W. Wood Products, Inc. may deny my application for employment or
            if it has already employed me, that W. W. Wood Products, Inc. may terminate my employment because
            of information obtained during W. W. Wood Products, Inc.’s investigation or confirmation, or
            both, of my responses made on my employment application. Upon the termination of my employment
            with W. W. Wood Products, Inc. regardless of when, how, or why my employment ends, and regardless
            of whether W. W. Wood Products, Inc. or I terminate my employment,
            I authorize W. W. Wood Products, Inc. to release information about my employment history with
            W. W. Wood Products, Inc. and release W. W. Wood Products, Inc. and all of its agents from any
            liability for the disclosure of information about my employment history to either governmental
            agencies or employers to whom I have applied for a job.
          </p>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <h3 class="mb-2">
            Certification and Authorization
          </h3>
          <p style="text-align: justify">
            I certify that I have given true and complete information in response to each category of
            information requested. I have also read, understood, and accepted the conditions of employment
            stated in this application. I further authorize the release of information as stated above.
            I recognize W. W. Wood Products, Inc.'s right either to revoke any employment offer or to
            terminate my employment if it ever finds any of my responses written on this application either
            to falsify or to omit, or both, any information. By providing my name and date below I agree
            to these terms.
          </p>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col sm="12" md="12">
          <div class = "pl-5 rounded-xl large-checkbox-background">
            <v-checkbox
              v-model = "application.agreedToTerms"
              label   = "Do you accept the terms above?"
              ref     = "Accept the Terms"
              class   = "large-checkbox"
              :rules  = "[v => v === true || 'You must agree to the terms above']"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>
      <v-row dense class="mt-0 pt-0">
        <v-col sm="12" md="6" class="pr-md-4">
          <v-text-field
            :value = "
              application.agreedToTerms
              ? application.firstName + ' ' + application.lastName
              : ''
            "
            label              = "Signed Name"
            ref                = "Signed Name"
            prepend-inner-icon = "mdi-pen"
            class              = "cursive cursive-name"
            st
            :rules             = "[v => !!v || 'Field is Required']"
            :disabled          = "!application.agreedToTerms"
            readonly
          ></v-text-field>
        </v-col>
        <v-col sm="12" md="6" class="pl-md-4">
          <v-text-field
            :value = "
              application.agreedToTerms
              ? new Intl.DateTimeFormat('en-US').format(new Date())
              : ''
            "
            label              = "Today's Date"
            ref                = "Signed Date"
            prepend-inner-icon = "mdi-calendar"
            class              = "cursive cursive-date"
            :rules             = "[v => !!v || 'Field is Required']"
            :disabled          = "!application.agreedToTerms"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mb-2">
        <v-col sm="12" md="8" class="text-sm-center text-md-left">
          <v-btn
            color     = "primary"
            :disabled = "postingApplication"
            @click    = "submitJobApplication"
          >
            Submit Application for Employment
          </v-btn>
        </v-col>
        <v-col sm="12" md="4" v-if="kioskMode === true" class="text-sm-center text-md-right">
          <v-btn
            :disabled = "postingApplication"
            @click    = "showCancelModal = true"
          >
            Cancel Application
          </v-btn>
        </v-col>
      </v-row>
      <div
        class = "pl-sm-2 text-sm-center text-md-left"
        style = "min-height: 36px"
      >
        <h2
          v-show = "message != ''"
          class  = "success--text"
        >
          {{ message }}
        </h2>
      </div>
    </v-form>
    <v-dialog
      v-model   = "showCancelModal"
      max-width = "600px"
    >
      <v-card class="py-3">
        <v-card-title class="text-h5 justify-center">
          <h4
            class = "text-center"
            style = "word-break: break-word"
          >
            Are you sure you want to cancel your application?
          </h4>
        </v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn
            @click = "showCancelModal = false"
          >
            No
          </v-btn>
          <v-btn
            color  = "error"
            @click = "$router.replace('/welcome')"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model = "snackbar.show"
      :color  = "snackbar.color"
      timeout = "10000"
      :top    = "true"
    >
      <div class="text-center">
        {{ snackbar.text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue                            from "vue";
import FormerEmployerTableComponent   from "@/components/applicationForm/formTables/FormerEmployerTableComponent.vue";
import EducationTableComponent        from "@/components/applicationForm/formTables/EducationTableComponent.vue";
import ReferenceTableComponent        from "@/components/applicationForm/formTables/ReferenceTableComponent.vue";
import ConvictionRecordTableComponent from "@/components/applicationForm/formTables/convictionRecordTableComponent.vue";
import JobRelatedSkillsComponent      from "@/components/applicationForm/formTables/JobRelatedSkillsComponent.vue";

import { mapStores                } from 'pinia';
import { useEducationStore        } from '@/stores/educationTable';
import { useReferenceStore        } from '@/stores/referenceTable';
import { useFormerEmployerStore   } from '@/stores/formerEmployerTable';
import { useConvictionRecordStore } from '@/stores/convictionRecordTable';
import { useJobSkillStore         } from '@/stores/jobSkillTable';
import { useCategoryLookupStore   } from '@/stores/lookupTable';
import { useApplicationStore      } from '@/stores/applicationTable';

import {
  Address,
  Category,
  Application,
  EntireApplication,
  ApplicationResponse,
  APIError,
  State,
} from "@/models/ApplicationForm";

interface ItemProp {
  text:  string;
  value: number;
}

interface Snackbar {
  show:  boolean;
  text:  string;
  color: string;
}

interface Data {
  Category:                  any;
  snackbar:                  Snackbar;
  kioskMode:                 boolean;
  application:               Application;
  petName:                   string;   // Used to block bot spam
  message:                   string;
  showStartDatePicker:       boolean;
  showAppliedHereDatePicker: boolean;
  showSignatureDatePicker:   boolean;
  showCancelModal:           boolean;
  postingApplication:        boolean;  // Determines if an application post is in progress
  date:                      Date;
}

export default Vue.extend({
  name: "ApplicationFormComponent",
  components: {
    ConvictionRecordTableComponent,
    ReferenceTableComponent,
    EducationTableComponent,
    FormerEmployerTableComponent,
    JobRelatedSkillsComponent
  },

  inject: [
    '$applicationFormService'
  ],

  data(): Data {
    return {
      Category,  // Added to allow access in HTML Template
      kioskMode: false,
      application: {
        socialSecurity: '',
        firstName:      '',
        middleName:     '',
        lastName:       '',
        address: {
          street:  '',
          city:    '',
          state:   null,
          zipcode: ''
        } as Address,
        phoneNumber:              '',
        emailAddress:             '',
        referralSourceId:         null,
        referralName:             null,
        over18:                   null,
        employmentPosition:       '',
        fullTime:                 null,
        startDate:                null,
        salaryDesired:            '',
        hasWorkedHereBefore:      null,
        currentlyEmployed:        null,
        inquirePresentEmployer:   null,
        involuntarilyDischarged:  null,
        explanationForDischarge:  null,
        jobCertifications:        null,
        otherJobSkills:           '',
        hasFormerEmployerHistory: true,
        organizationMembership:   null,
        examinationAndDrugTest:   null,
        agreedToTerms:            false,
        convictedOfMisdemeanorOrFelony: null,
        hasPhotoId:               null,
        hasPhysicalSsnCard:       null
      },
      snackbar: {
        text:  '',
        show:  false,
        color: "error"
      },
      petName:                   '',
      message:                   '',
      showStartDatePicker:       false,
      showAppliedHereDatePicker: false,
      showSignatureDatePicker:   false,
      showCancelModal:           false,
      postingApplication:        false,
      date:                      new Date
    }
  },

  computed: {
    ...mapStores (
      useApplicationStore,
      useEducationStore,
      useReferenceStore,
      useFormerEmployerStore,
      useConvictionRecordStore,
      useJobSkillStore,
      useCategoryLookupStore,
    ),

    isMobile(): boolean {
      return this.$vuetify.breakpoint.width < 700;
    },

    isOtherJobSkillSelected(): boolean {
      const other = this.jobSkillStore.getSelectableJobSkill(11);

      if (other != undefined) {
        return other.selected;
      }
      else {
        return false;
      }
    },
  },

  methods: {
    /**
     * @name getStates
     * @description - Converts a full Enum type into an array useable by Vuetify
     * @returns - An array of objects that contain key/value pairs for each state
     */
    getStates() {
      const states = [];

      for (const [key, value] of Object.entries(State)) {
        states.push(
          {
            key:   key,
            value: value,
          }
        );
      }

      return states
    },

    // Formats the date into MM-dd-yyyy in the text fields
    formatDate(dateString: string | null): string | null {
      if (dateString == null) {
        return null;
      }

      const [year, month, day] = dateString.split('-')
      return `${month}-${day}-${year}`;
    },

    // Gets an array of categories to be used in the dropdown menus where
    // text is the text the user will see in the menu and value is the lookupID
    // that will be stored when a user selects an option
    getCategory(cat: Category): ItemProp[] {
      return this.categoryLookupStore
        .getSortedCategoryOptions(cat)
        .map( opt => {
          return {
            text:  opt.optName,
            value: opt.catLookupId,
          } as ItemProp
        });
    },

    /**
     * @name genericFieldRule
     * @input maxLength - The maximum amount of characters aloud
     * @desc Adds both required input and maxLength validation constraints
     */
    genericFieldRule(maxLength: number) {
      return [
        (v: any) => !!v || 'Field is Required',
        (v: any) => (v && v.length <= maxLength) || 'Field must be less than ' + (maxLength + 1) + ' characters',
      ];
    },

    /**
     * @name radioRequired
     * @desc Adds a required input constraint to a radio group
     */
    radioRequired() {
      return [
        (v: any) => !(v == null) || 'Select an Option',
      ];
    },

    /**
     * @name validateSignedDate
     * @input dateString - A date in MM-dd-yyyy string format
     * @desc Converts the datestring to an appropriate Date object and compares it with today
     * @returns True if the dateString is today and false otherwise
     */
    validateSignedDate(dateString: string): boolean {
      const today = new Date;
      const [month, day, year] = dateString.split('-');

      // Creates a new date object using the provided string month/day/year variables
      const dateToCheck = new Date(
        Number.parseInt(year),
        Number.parseInt(month) - 1,
        Number.parseInt(day)
      );

      // Checks to see if the two dates are equal
      return dateToCheck.toDateString() === today.toDateString();
    },

    /**
     * @name validateApplication
     * @desc Checks to see if the application form is valid or not
     * @returns {valid: boolean, message: string} - An object denoting if the
     *    form is valid or not and any associated error message
     */
    validateApplication(): {valid: boolean, message: string} {
      let vForm = this.$refs.applicationForm as Vue & { validate: () => boolean };

      if (vForm == undefined) {
        return {
          valid: false,
          message: "A field is incorrect"
        };
      }

      // Validates all form fields
      if (!vForm.validate()) {
        let firstInvalid = this.firstInvalidField();

        if (firstInvalid != null) {
          return {
            valid: false,
            message: `${firstInvalid} is incorrect`
          };
        }
        return {
          valid: false,
          message: "A field is incorrect"
        };
      }

      // Ensures there are at least 3 references
      if (this.referenceStore.references.length < 3) {
        return {
          valid: false,
          message: "Please include at least 3 references"
        };
      }

      // Requires conviction record details if convicted of misdemeanor or felony
      if (
        this.application.convictedOfMisdemeanorOrFelony &&
        this.convictionRecordStore.convictionRecords.length < 1
      ) {
        return {
          valid: false,
          message: "Please include details about your conviction records"
        };
      }

      // Requires at least one education record
      if (this.educationStore.education.length === 0) {
        return {
          valid: false,
          message: "Please include at least one school in your education history"
        };
      }

      // Requires a former employer record if they said they have employment history
      if (
        this.application.hasFormerEmployerHistory &&
        this.formerEmployerStore.formerEmployers.length < 1
      ) {
        return {
          valid: false,
          message: "Please include your four most recent former employers"
        };
      }

      // Returns true if the application is valid
      return {
        valid: true,
        message: ""
      };
    },

    /**
     * @name submitJobApplication
     * @desc Pulls all weak entity objects from the store and calls the
     *      applicationFormService to query the API
     */
    async submitJobApplication() {
      const validationResult = this.validateApplication();

      // Go to error page if a bot has filled out the honeypot field
      if (this.petName != '') {
        await this.$router.replace("/application-submit-error");
        return;
      }

      // Only continues if the form is valid and not currently submitting application
      if (
        !validationResult.valid ||
        this.postingApplication
      ) {
        this.snackbar = {
          text:  validationResult.message,
          color: "error",
          show:  true
        }

        let invalidField = this.firstInvalidField();
        if (invalidField != null) {
          this.focusField(invalidField);
        }
        return;
      }

      // Disables submit button to prevent multiple applications being submitted at once
      this.postingApplication = true;
      this.applicationStore.isFilledOut = true;
      this.message = "Submitting...";

      // Creates an object containing all the application data
      const entireApplication = {
        application:       this.application,
        references:        this.referenceStore.references,
        education:         this.educationStore.education,
        convictionRecords: this.convictionRecordStore.convictionRecords,
        formerEmployers:   this.formerEmployerStore.formerEmployers,
        jobSkills:         this.jobSkillStore.getSelectedJobSkills,
      } as EntireApplication

      console.log("Sending application to ApplicationFormService");

      // Calls the applicationFormService to contact the API
      const response: ApplicationResponse =
        await this
          .$applicationFormService
          .postApplication(entireApplication);

      // Routes to success page if API returns successful 200
      if (response.status === 200) {
        await this.$router.replace("/application-submit-success");
      }
      // Routes to the error page if there is no error response from the API.
      // This conditional should cover all non 400s
      else if (
        response.status !== 400  ||
        response.body   ==  null
      ) {
        console.warn("The API returned an unknown error response");
        await this.$router.replace("/application-submit-error");
      }
      // Routes to the too recent page if the error response indicates the
      // API error was because the application is too recent
      else if (response.body.error === APIError.APPLICATION_TOO_RECENT) {
        await this.$router.replace("/application-submit-recent");
      }
      // Routes to the error page if the API gives another known error
      else {
        await this.$router.replace("/application-submit-error");
      }

    },

    /**
     * @name focusField
     * @desc Focuses the provided field in the DOM
     * @ref - A string representing an element reference string in the DOM
     */
    focusField(ref: string): void {
      //@ts-ignore
      this.$refs[ref].$el.scrollIntoView({
        behavior: 'smooth',
        block:    'center'
      });
    },

    /**
     * @name firstInvalidField
     * @desc Finds the first invalid form field and returns its reference name
     * @returns A string of the component's reference name or null if none is invalid
     */
    firstInvalidField(): string | null {
      for (const ref in this.$refs) {
        // TS isn't correctly typing these refs
        //@ts-ignore
        if (this.$refs[ref] != null && this.$refs[ref].hasError) {
          return ref;
        }
      }
      return null;
    },

    setFormerEmployerHistory(newVal: boolean | null) {
      if (newVal == null || !newVal) {
        this.application.hasFormerEmployerHistory = false;
      }
      else {
        this.application.hasFormerEmployerHistory = true;
      }
    }
  },

  // Resets the application in the ApplicationStore when this component is loaded
  mounted() {
    this.kioskMode = localStorage.getItem("KioskMode") === "true" ?? false;
    this.applicationStore.resetApplication();
  },

  // Updates the application in the ApplicationStore when this component is unloaded
  beforeDestroy() {
    this.applicationStore.application = this.$data.application;
  },

});
</script>


<style scoped>
  .v-text-field {
    font-size: 1.05em;
  }

  .pet-css {
    visibility: hidden;
    position: absolute;
  }

  .cursive input {
    font-family: "Cedarville Cursive", cursive;
    font-weight: 400;
    font-style:  normal;
  }
</style>


<style>
  .large-checkbox-background {
    padding-top:    1px;
    padding-bottom: 1px;
    background:     rgba(128,128,128,0.08);
  }

  @media (max-width: 599px) {
    .large-checkbox-background {
      width: 305px;
    }

    .cursive input {
      font-family:    "Cedarville Cursive", cursive;
      font-weight:    400;
      max-height:     36px;
      padding-left:   4px;
      font-style:     normal;
    }

    .cursive-name {
      font-size: 23px !important;
    }

    .cursive-date {
      font-size: 21px !important;
    }
  }

  @media (min-width: 600px) {
    .large-checkbox-background {
      width: 330px;
    }

    .large-checkbox .v-icon {
      font-size: 32px;
    }

    .large-checkbox input {
      height: 32px;
      width:  32px;
    }

    .large-checkbox .v-input--selection-controls__ripple {
      height: 39px;
      width:  39px;
      left:   -15px;
      top:    calc(50% - 26px);
    }

    .large-checkbox .v-label {
      font-size:    18px;
      padding-left: 4px;
    }

    .cursive input {
      font-family:    "Cedarville Cursive", cursive;
      font-weight:    400;
      max-height:     42px;
      padding-left:   4px;
      font-style:     normal;
    }

    .cursive-name {
      font-size: 30px !important;
    }

    .cursive-date {
      font-size: 26px !important;
    }
  }
</style>
