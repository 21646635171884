import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      sm: 800,
      md: 1100,
      lg: 1600,
    }
  },
  theme: {
    themes: {
      light: {
        background: '#FFFFFF',
        primary:    '#5B91C1',
        secondary:  '#215F97',
        accent:     '#ECB772',
        error:      '#FF5252',
        info:       '#2196F3',
        success:    '#4CAF50',
        warning:    '#F3BA4D',
        textPri:    '#215F97',
        textSec:    '#36383B',
        textTri:    '#676D7C',
        text:       '#000000',
      },
      dark: {
        background: '#16181A',
        primary:    '#215F97',
        secondary:  '#5B91C1',
        accent:     '#ECB772',
        error:      '#DF3838',
        info:       '#2196F3',
        success:    '#4CAF50',
        warning:    '#F3BA4D',
        textPri:    '#5B91C1',
        textSec:    '#D0DDE5',
        textTri:    '#BBBBBF',
        text:       '#DDDDDD'
      }
    },
    options: { customProperties: true },
  },
});
