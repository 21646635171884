<template>
  <v-data-table
    :headers          = "formerEmployersTableHeaders"
    :items            = "formerEmployerStore.formerEmployers"
    class             = "elevation-3"
    :no-data-text     = "
      hasFormerEmployerHistory
      ? 'Please add your four most recent employers'
      : 'Selected no employment history'
    "
    mobile-breakpoint = "1000"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat rounded>
        <v-toolbar-title v-if="!isMobile">
          Former Employers
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Employers
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <h4
          v-if  = "formerEmployerCapReached"
          class = "warning--text text-right"
        >
          Can only add 4
        </h4>
        <v-btn
          @click    = "newEmployerModal()"
          color     = "primary"
          class     = "ml-4"
          v-if      = "!(formerEmployerCapReached && isMobile)"
          :disabled = "formerEmployerCapReached"
          dark
        >
          Add Employer
        </v-btn>
        <v-dialog
          v-model   = "showEmployerModal"
          max-width = "1100px"
          persistent
        >
          <v-form
            ref="formerEmployerForm"
            lazy-validation
          >
            <v-card>
              <v-card-title class="primary py-3">
                <h2 class="my-0 ml-3">
                  Former Employer
                </h2>
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model   = "tempFormerEmployer.employerName"
                        label     = "Employer Name"
                        :rules    = "genericFieldRule(64)"
                        :disabled = "!hasFormerEmployerHistory"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model   = "tempFormerEmployer.employerAddress"
                        label     = "Employer Address"
                        :rules    = "genericFieldRule(128)"
                        :disabled = "!hasFormerEmployerHistory"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-menu
                        v-model                 = "showStartDatePicker"
                        :close-on-content-click = "false"
                        :nudge-right            = "40"
                        transition              = "scale-transition"
                        min-width               = "auto"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value             = "formatDate(tempFormerEmployer.startDate)"
                            label              = "Start Date"
                            prepend-inner-icon = "mdi-calendar"
                            :disabled          = "!hasFormerEmployerHistory"
                            v-bind             = "attrs"
                            v-on               = "on"
                            :rules             = "[
                              v => !!v || 'Field is Required'
                            ]"
                            readonly
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model = "tempFormerEmployer.startDate"
                          @input  = "showStartDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-menu
                        v-model                 = "showEndDatePicker"
                        :close-on-content-click = "false"
                        :nudge-right            = "40"
                        transition              = "scale-transition"
                        min-width               = "auto"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value             = "formatDate(tempFormerEmployer.endDate)"
                            @change            = "(val) => tempFormerEmployer.endDate = val"
                            label              = "End Date"
                            prepend-inner-icon = "mdi-calendar"
                            :disabled          = "!hasFormerEmployerHistory"
                            v-bind             = "attrs"
                            v-on               = "on"
                            clearable
                            readonly
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model = "tempFormerEmployer.endDate"
                          @input  = "showEndDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model   = "tempFormerEmployer.positionTitle"
                        :rules    = "genericFieldRule(50)"
                        :disabled = "!hasFormerEmployerHistory"
                        label     = "Position Title"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model   = "tempFormerEmployer.salary"
                        label     = "Salary Upon Leaving"
                        :disabled = "!hasFormerEmployerHistory"
                        :rules    = "genericFieldRule(64)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12">
                      <v-text-field
                        v-model   = "tempFormerEmployer.reasonForLeaving"
                        :rules    = "genericFieldRule(64)"
                        :disabled = "!hasFormerEmployerHistory"
                        label     = "Reason for Leaving"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense v-if = "formerEmployerStore.formerEmployers.length === 0 && getWidth() < 800">
                    <v-col sm="12">
                      <v-checkbox
                        :value  = "!hasFormerEmployerHistory"
                        @change = "newVal => $emit('hasFormerEmployerHistoryUpdated', !newVal)"
                        label   = "I don't have any employment history"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="mx-md-4">
                <v-checkbox
                  v-if    = "formerEmployerStore.formerEmployers.length === 0 && getWidth() >= 800"
                  :value  = "!hasFormerEmployerHistory"
                  @change = "newVal => $emit('hasFormerEmployerHistoryUpdated', !newVal)"
                  label   = "I don't have any employment history"
                ></v-checkbox>
                <v-spacer />
                <div v-if="hasFormerEmployerHistory">
                  <v-btn
                    color  = "text"
                    @click = "closeEmployerModal()"
                    text
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color  = "secondary"
                    @click = "saveEmployerModal()"
                    text
                  >
                    Save
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    color  = "primary"
                    @click = "closeEmployerModal()"
                    dark
                  >
                    Close
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-dialog
        v-model   = "showEmployerDeleteModal"
        max-width = "600px"
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Are you sure you want to delete this Employer?
            </h4>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color  = "primary"
              @click = "hideDeleteEmployerModal()"
            >
              Cancel
            </v-btn>
            <v-btn
              color  = "error"
              @click = "deleteEmployer()"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model   = "showFollowupEmployerModal"
        max-width = "600px"
        persistent
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Do you have additional employment history?
            </h4>
          </v-card-title>
          <v-card-actions style="justify-content: center">
            <v-btn
              @click = "showFollowupEmployerModal = false"
            >
              No
            </v-btn>
            <v-btn
              color  = "primary"
              @click = "newEmployerModal"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          class  = "ml-1 mr-1"
          @click = "editEmployerModal(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          @click="deleteEmployerModal(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.startDate="{ item }">
      {{ formatDate(item.startDate) }}
    </template>
    <template v-slot:item.endDate="{ item }">
      {{ formatDate(item.endDate) }}
    </template>
  </v-data-table>
</template>


<script lang="ts">
import Vue from "vue";
import { mapStores              } from 'pinia';
import { FormerEmployer         } from "@/models/ApplicationForm";
import { useFormerEmployerStore } from '@/stores/formerEmployerTable';

export default Vue.extend({
  name: "FormerEmployerTableComponent",

  props: {
    hasFormerEmployerHistory: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      formerEmployersTableHeaders: [
        {
          text:     'Employer Name',
          align:    'center',
          sortable: false,
          value:    'employerName'
        },
        { text:     'Employer Address',
          align:    'center',
          sortable: false,
          value:    'employerAddress'
        },
        { text:     'Start Date',
          align:    'center',
          sortable: false,
          value:    'startDate',
          width:    '115px'
        },
        { text:     'End Date',
          align:    'center',
          sortable: false,
          value:    'endDate',
          width:    '115px'
        },
        { text:     'Position Title',
          align:    'center',
          sortable: false,
          value:    'positionTitle'
        },
        { text:     'Salary upon Leaving',
          align:    'center',
          sortable: false,
          value:    'salary'
        },
        { text:     'Reason for Leaving',
          align:    'center',
          sortable: false,
          value:    'reasonForLeaving'
        },
        { text:     'Actions',
          align:    'center',
          sortable: false,
          value:    'actions',
          width:    '90px'
        }
      ],
      tempFormerEmployer: {
        startDate:        null,
        endDate:          null,
        employerName:     "",
        employerAddress:  "",
        salary:           "",
        positionTitle:    "",
        reasonForLeaving: ""
      } as FormerEmployer,
      showEmployerModal:         false,
      showEmployerDeleteModal:   false,
      showFollowupEmployerModal: false,
      currentlyEditing:          false,
      employerIndex:             -1,
      showStartDatePicker:       false,
      showEndDatePicker:         false
    }
  },

  computed: {
    ...mapStores(useFormerEmployerStore),

    formerEmployerCapReached(): boolean {
      return this.formerEmployerStore.formerEmployers.length >= 4;
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.width < 700;
    }
  },

  methods: {
    validateFormerEmployer (): boolean {
      let vForm = this.$refs.formerEmployerForm as Vue & {validate: () => boolean};

      if (vForm == undefined) {
        return false;
      }

      return vForm.validate();
    },

    resetValidation (): void {
      let vForm = this.$refs.formerEmployerForm as Vue & {resetValidation: () => void};

      if (vForm != undefined) {
        vForm.resetValidation();
      }
    },

    // Formats the date into MM-dd-yyyy in the text fields
    formatDate(dateString: string | null): string | null {
      if (dateString == null) {
        return null;
      }

      const [year, month, day] = dateString.split('-')
      return `${month}-${day}-${year}`;
    },

    newEmployerModal() {
      this.showFollowupEmployerModal = false;

      this.tempFormerEmployer = {
        startDate:        null,
        endDate:          null,
        employerName:     "",
        employerAddress:  "",
        salary:           "",
        positionTitle:    "",
        reasonForLeaving: ""
      } as FormerEmployer;
      this.employerIndex = -1;

      this.resetValidation();

      this.currentlyEditing  = false;
      this.showEmployerModal = true;
    },

    closeEmployerModal() {
      this.tempFormerEmployer = {
        startDate:        null,
        endDate:          null,
        employerName:     "",
        employerAddress:  "",
        salary:           "",
        positionTitle:    "",
        reasonForLeaving: ""
      } as FormerEmployer;
      this.employerIndex     = -1;
      this.showEmployerModal = false;
      this.currentlyEditing  = false;
    },

    saveEmployerModal() {
      // Verifies the form is correct before saving or updating
      if (this.validateFormerEmployer()) {
        // Adds a new employer if the index is less than 0 or greater than the array
        if (
          this.employerIndex < 0 ||
          this.employerIndex > this.formerEmployerStore.formerEmployers.length
        ) {
          // Adds a new employer
          this.formerEmployerStore.addFormerEmployer(this.tempFormerEmployer);
        } else {
          // Updates an existing employer
          this.formerEmployerStore.updateFormerEmployer(this.employerIndex, this.tempFormerEmployer);
        }

        this.tempFormerEmployer = {
          startDate:        null,
          endDate:          null,
          employerName:     "",
          employerAddress:  "",
          salary:           "",
          positionTitle:    "",
          reasonForLeaving: ""
        } as FormerEmployer;
        this.employerIndex     = -1;
        this.showEmployerModal = false;

        this.showFollowupEmployerModal =
          !this.currentlyEditing
          && !this.formerEmployerCapReached;
      }
    },

    editEmployerModal(employer: FormerEmployer) {
      this.employerIndex      = this.formerEmployerStore.formerEmployers.indexOf(employer);
      this.tempFormerEmployer = Object.assign(
        {}, this.formerEmployerStore.formerEmployers[this.employerIndex]
      ) as FormerEmployer;

      this.resetValidation();
      this.currentlyEditing  = true;
      this.showEmployerModal = true;
    },

    deleteEmployerModal (employer: FormerEmployer) {
      this.employerIndex = this.formerEmployerStore.formerEmployers.indexOf(employer);
      this.showEmployerDeleteModal = true;
    },

    hideDeleteEmployerModal() {
      this.showEmployerDeleteModal = false;
      this.employerIndex           = -1;
    },

    deleteEmployer() {
      if (
        this.employerIndex >= 0 &&
        this.employerIndex < this.formerEmployerStore.formerEmployers.length
      ) {
        this.formerEmployerStore.removeFormerEmployer(this.employerIndex);
        this.employerIndex           = -1;
        this.showEmployerDeleteModal = false;
      }
    },

    genericFieldRule (maxLength: number) {
      return [
        (v:any) => !!v || 'Field is Required',
        (v:any) => (v && v.length <= maxLength) || 'Field must be less than ' + (maxLength + 1) + ' characters',
      ];
    },

    getWidth(): number {
      return visualViewport.width;
    }
  },

  watch: {
    hasFormerEmployerHistory(newVal, oldVal) {
      this.resetValidation();
    }
  }

});
</script>

<style scoped>

</style>
