import { Education } from '@/models/ApplicationForm';
import { defineStore } from 'pinia';

interface IState {
  education: Education[];
}

export const useEducationStore = defineStore('education', {

  state: (): IState => ({
    education: []
  }),

  actions: {
    addEducation (education: Education) {
      this.education.push(education);
    },

    updateEducation (index: number, updatedEducation: Education) {
      if (0 <= index && index < this.education.length) {
        this.education.splice(index, 1, updatedEducation);
      }
    },

    removeEducation (indexToRemove: number) {
      if (0 <= indexToRemove && indexToRemove < this.education.length) {
        this.education.splice(indexToRemove, 1);
      }
    },
  },

  getters: {
    getEducation(): Education[] {
      return this.education;
    },
  }

});
