import { ConvictionRecord } from '@/models/ApplicationForm';
import { defineStore } from 'pinia';

interface IState {
  convictionRecords: ConvictionRecord[];
}

export const useConvictionRecordStore = defineStore('convictionRecord', {

  state: (): IState => ({
    convictionRecords: []
  }),

  actions: {
    addConvictionRecord (convictionRecord: ConvictionRecord) {
      this.convictionRecords.push(convictionRecord);
    },

    updateConvictionRecord (index: number, updatedConvictionRecord: ConvictionRecord) {
      if (0 <= index && index < this.convictionRecords.length) {
        this.convictionRecords.splice(index, 1, updatedConvictionRecord);
      }
    },

    removeConvictionRecord (indexToRemove: number) {
      if (0 <= indexToRemove && indexToRemove < this.convictionRecords.length) {
        this.convictionRecords.splice(indexToRemove, 1);
      }
    },

  },

});
