<template>
  <div>
    <header-component title="Privacy Policy" />
    <privacy-policy-component />
  </div>
</template>

<script lang="ts">
import Vue                    from "vue";
import HeaderComponent        from "/src/components/HeaderComponent.vue";
import PrivacyPolicyComponent from "/src/components/PrivacyPolicyComponent.vue";

export default Vue.extend({
  name: "PrivacyPolicy",

  components: {
    HeaderComponent,
    PrivacyPolicyComponent,
  },

  data() {
    return {
    }
  },

});
</script>

<style scoped>

</style>
