<template>
  <div>
    <header-component title="Application Submitted" />
    <application-submit-success-component />
  </div>
</template>

<script lang="ts">
import HeaderComponent                   from "/src/components/HeaderComponent.vue";
import ApplicationSubmitSuccessComponent from "/src/components/applicationForm/ApplicationSubmitSuccessComponent.vue";

export default {
  name: "ApplicationFormSuccess",

  components: {
    HeaderComponent,
    ApplicationSubmitSuccessComponent
  }
}
</script>

<style scoped>

</style>
