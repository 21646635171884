<template>
  <!-- Desktop Footer -->
  <div
    v-if  = "$vuetify.breakpoint.width > 629"
    style = "margin-top: 130px"
  >
    <v-footer
      class = "white--text py-3"
      color = "primary"
      absolute
    >
      <img
        :src  = "require('/src/assets/WWLogoWhite.png')"
        style = "width: 130px"
      />
      <v-spacer />
      <div>
        <span>
          © {{ currentYear }} W. W. Wood Products, Inc. |
        </span>
        <a href = "/privacy-policy">
          privacy policy
        </a>
      </div>
      <v-spacer />
      <version-component />
    </v-footer>
  </div>

  <!-- Mobile Footer -->
  <div
    v-else
    style = "margin-top: 180px;"
  >
    <v-footer
      class = "white--text py-3 text-center"
      color = "primary"
      absolute
    >
      <div class="row mt-0">
        <div class="col-12 py-0">
          <img
            :src  = "require('/src/assets/WWLogoWhite.png')"
            style = "width: 130px"
          />
        </div>
        <div class="col-12 py-0">
          <span>
            © {{ currentYear }} W. W. Wood Products, Inc. |
          </span>
          <a
            class  = "accent--text"
            href   = "/privacy-policy"
          >
            privacy policy
          </a>
        </div>
        <div class="col-12 py-0">
          <version-component />
        </div>
      </div>
    </v-footer>
  </div>
</template>


<script lang="ts">
import Vue              from "vue";
import VersionComponent from '@/components/VersionComponent.vue';

export default Vue.extend({
  name: "FooterComponent",

  components: {
    VersionComponent,
  },

  data() {
    return {
    }
  },

  computed: {
    currentYear(): String {
      return new Date().getFullYear().toString();
    }
  },

  methods: {
  },

});
</script>

<style scoped>

</style>
