<template>
  <small class="version">Version {{version}}</small>
</template>

<script lang="ts">
import Vue from "vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pjson = require("../../package.json");


export default Vue.extend({
  name: "VersionComponent",

  components: {
  },

  inject: [
    '$applicationFormService'
  ],

  data()  {
    return {
      version: pjson.version
    }
  }
});
</script>

<style scoped>

.version {
  /* position:         fixed; */
  /* z-index:          10000; */
  /* right:            0; */
  /* bottom:           0; */
  /* margin-right:     17px; */
  /* padding:          3px; */
  font-family:      sans-serif;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border-radius:    3px; */
}

small {
  font-size: 0.875em;
}
</style>
