<template>
  <div>
    <header-component title="Application Error" />
    <application-submit-error-component />
  </div>
</template>

<script lang="ts">
import HeaderComponent                 from "/src/components/HeaderComponent.vue";
import ApplicationSubmitErrorComponent from "/src/components/applicationForm/ApplicationSubmitErrorComponent.vue";

export default {
  name: "ApplicationFormError",

  components: {
    HeaderComponent,
    ApplicationSubmitErrorComponent
  }
}
</script>

<style scoped>

</style>
