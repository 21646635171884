<template>
  <v-data-table
    :headers          = "convictionRecordTableHeaders"
    :items            = "convictionRecordStore.convictionRecords"
    class             = "elevation-3"
    mobile-breakpoint = "1000"
    no-data-text      = "Please add every conviction or plea"
    hide-default-footer
>
    <template v-slot:top>
      <v-toolbar flat rounded>
        <v-toolbar-title>
          Conviction Records
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click = "newConvictionRecordModal()"
          color  = "primary"
          dark
        >
          Add Record
        </v-btn>
        <v-dialog
          v-model   = "showConvictionRecordModal"
          max-width = "1100px"
          persistent
        >
          <v-form
            ref="convictionRecordForm"
            lazy-validation
          >
            <v-card>
              <v-card-title class="primary py-3">
                <h2 class="my-0 ml-3">
                  Conviction Record
                </h2>
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <v-col sm="12" md="12">
                      <h3 class="mb-4 text--text">
                        Nature of the offense for which you were convicted or pleaded guilty
                      </h3>
                      <v-text-field
                        v-model = "tempConvictionRecord.offenseNature"
                        label   = "Offense Nature"
                        :rules  = "genericFieldRule(128)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12">
                      <v-text-field
                        v-model = "tempConvictionRecord.judgement"
                        label   = "Judgment Imposed"
                        :rules  = "genericFieldRule(128)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12">
                      <v-menu
                        v-model                 = "showConvictionDatePicker"
                        :close-on-content-click = "false"
                        :nudge-right            = "40"
                        transition              = "scale-transition"
                        min-width               = "auto"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value  = "formatDate(tempConvictionRecord.convictionDate)"
                            label   = "Date of conviction or the entering of the plea"
                            :rules  = "[v => !!v || 'Field is Required']"
                            v-bind  = "attrs"
                            v-on    = "on"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model = "tempConvictionRecord.convictionDate"
                          @input  = "showConvictionDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempConvictionRecord.courtName"
                        label   = "Name of court imposing judgment"
                        :rules  = "genericFieldRule(64)"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model = "tempConvictionRecord.courtAddress"
                        label   = "Address of court imposing judgment"
                        :rules  = "genericFieldRule(128)"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="mx-md-4">
                <v-spacer></v-spacer>
                <v-btn
                  color  = "text"
                  @click = "closeConvictionRecordModal()"
                  text
                >
                  Cancel
                </v-btn>
                <v-btn
                  color  = "secondary"
                  @click = "saveConvictionRecordModal()"
                  text
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-dialog
        v-model   = "showConvictionRecordDeleteModal"
        max-width = "700px"
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Are you sure you want to delete this Conviction Record?
            </h4>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color  = "primary"
              @click = "hideDeleteConvictionRecordModal()"
            >
              Cancel
            </v-btn>
            <v-btn
              color  = "error"
              @click = "deleteConvictionRecord()"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model   = "showFollowupModal"
        max-width = "600px"
        persistent
      >
        <v-card class="py-3">
          <v-card-title class="text-h5 justify-center">
            <h4
              class = "text-center"
              style = "word-break: break-word"
            >
              Do you have additional conviction records?
            </h4>
          </v-card-title>
          <v-card-actions style="justify-content: center">
            <v-btn
              @click = "showFollowupModal = false"
            >
              No
            </v-btn>
            <v-btn
              color  = "primary"
              @click = "newConvictionRecordModal"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class  = "ml-1 mr-1"
        @click = "editConvictionRecordModal(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        @click="deleteConvictionRecordModal(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.convictionDate="{ item }">
      {{ formatDate(item.convictionDate) }}
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue                          from "vue";
import { mapStores                } from 'pinia';
import { ConvictionRecord         } from "@/models/ApplicationForm";
import { useConvictionRecordStore } from '@/stores/convictionRecordTable';

export default Vue.extend({
  name: "ConvictionRecordTableComponent",

  data() {
    return {
      convictionRecordTableHeaders: [
        {
          text:     'Nature of Offense',
          align:    'center',
          sortable: false,
          value:    'offenseNature'
        },
        {
          text:     'Judgment',
          align:    'center',
          sortable: false,
          value:    'judgement'
        },
        {
          text:     'Conviction/Plea Date',
          align:    'center',
          sortable: false,
          value:    'convictionDate',
          width:    '115px'
        },
        {
          text:     'Court Name',
          align:    'center',
          sortable: false,
          value:    'courtName'
        },
        {
          text:     'Court Address',
          align:    'center',
          sortable: false,
          value:    'courtAddress'
        },
        {
          text:     'Actions',
          align:    'center',
          sortable: false,
          value:    'actions',
          width:    '90px'
        }
      ],
      tempConvictionRecord: {
        offenseNature:  "",
        convictionDate: null,
        judgement:      "",
        courtName:      "",
        courtAddress:   ""
      } as ConvictionRecord,
      showConvictionRecordModal:       false,
      showConvictionRecordDeleteModal: false,
      showFollowupModal:               false,
      currentlyEditing:                false,
      convictionRecordIndex:           -1,
      showConvictionDatePicker:        false
    }
  },

  computed: {
    ...mapStores(useConvictionRecordStore),
  },

  methods: {
    newConvictionRecordModal() {
      this.showFollowupModal = false;
      this.tempConvictionRecord = {
        offenseNature:  "",
        convictionDate: null,
        judgement:      "",
        courtName:      "",
        courtAddress:   ""
      } as ConvictionRecord;
      this.convictionRecordIndex = -1;
      this.resetValidation();
      this.showConvictionRecordModal = true;
      this.currentlyEditing = false;
    },

    closeConvictionRecordModal() {
      this.tempConvictionRecord = {
        offenseNature:  "",
        convictionDate: null,
        judgement:      "",
        courtName:      "",
        courtAddress:   ""
      } as ConvictionRecord;
      this.convictionRecordIndex     = -1;
      this.showConvictionRecordModal = false;
      this.currentlyEditing = false;
    },

    validateConvictionRecord (): boolean {
      let vForm = this.$refs.convictionRecordForm as Vue & {validate: () => boolean};

      if (vForm == undefined) {
        return false;
      }

      return vForm.validate();
    },

    resetValidation (): void {
      let vForm = this.$refs.convictionRecordForm as Vue & {resetValidation: () => void};

      if (vForm != undefined) {
        vForm.resetValidation();
      }
    },

    // Formats the date into MM-dd-yyyy in the text fields
    formatDate(dateString: string | null): string | null {
      if (dateString == null) {
        return null;
      }

      const [year, month, day] = dateString.split('-')
      return `${month}-${day}-${year}`;
    },

    saveConvictionRecordModal () {
      // Verifies the form is correct before saving or updating
      if (this.validateConvictionRecord()) {
        // Adds a new Conviction Record if the index is less than 0 or greater than the array
        if (
          this.convictionRecordIndex < 0 ||
          this.convictionRecordIndex > this.convictionRecordStore.convictionRecords.length
        ) {
          // Adds a new conviction record
          this.convictionRecordStore.addConvictionRecord(this.tempConvictionRecord);
        } else {
          // Updates an existing conviction record
          this.convictionRecordStore.updateConvictionRecord(this.convictionRecordIndex, this.tempConvictionRecord);
        }

        this.tempConvictionRecord = {
          offenseNature:  "",
          convictionDate: null,
          judgement:      "",
          courtName:      "",
          courtAddress:   ""
        } as ConvictionRecord;
        this.convictionRecordIndex     = -1;
        this.showConvictionRecordModal = false;

        this.showFollowupModal = !this.currentlyEditing;
      }
    },

    editConvictionRecordModal (convictionRecord: ConvictionRecord) {
      this.convictionRecordIndex = this.convictionRecordStore.convictionRecords.indexOf(convictionRecord);
      this.tempConvictionRecord  = Object.assign(
        {}, this.convictionRecordStore.convictionRecords[this.convictionRecordIndex]
      ) as ConvictionRecord;

      this.resetValidation();

      this.currentlyEditing = true;
      this.showConvictionRecordModal = true;
    },

    deleteConvictionRecordModal (convictionRecord: ConvictionRecord) {
      this.convictionRecordIndex = this.convictionRecordStore.convictionRecords.indexOf(convictionRecord);
      this.showConvictionRecordDeleteModal = true;
    },

    hideDeleteConvictionRecordModal () {
      this.showConvictionRecordDeleteModal = false;
      this.convictionRecordIndex           = -1;
    },

    deleteConvictionRecord () {
      if (
        this.convictionRecordIndex >= 0 &&
        this.convictionRecordIndex < this.convictionRecordStore.convictionRecords.length
      ) {
        this.convictionRecordStore.removeConvictionRecord(this.convictionRecordIndex);
        this.convictionRecordIndex           = -1;
        this.showConvictionRecordDeleteModal = false;
      }
    },

    genericFieldRule (maxLength: number) {
      return [
        (v:any) => !!v || 'Field is Required',
        (v:any) => (v && v.length <= maxLength) || 'Field must be less than ' + (maxLength + 1) + ' characters',
      ];
    },
  },

});
</script>

<style scoped>

</style>
